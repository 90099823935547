export const saleCategoryData = [
  { value: "House & Apartments", label: "House & Apartments" },
  { value: "Shops & Offices", label: "Shops & Offices" },
  { value: "Lands & Plots", label: "Lands & Plots" },
  { value: "PG & Guest Houses", label: "PG & Guest Houses" },
];

export const typeOfHouseData = [
  "Flats/Apartments",
  "Independent/Builder Floors",
  "Farm House",
  "Hosue & villa",
];

export const FurnishedData = ["Furnished", "Semi-Furnished", "Unfurnished"];

export const projectStatusData = [
  "New Launch",
  "Ready to Move",
  "Under Construction",
];

export const listedByData = ["Builder", "Dealer", "Owner"];

export const facingData = [
  {value: "East", label: "East",},
  { value: "West", label: "West" },
  { value: "South", label: "South" },
  { value: "North", label: "North" },
  { value: "North-East", label: "North-East" },
  { value: "North-West", label: "North-West" },
  { value: "South-East", label: "South-East" },
  { value: "South-West", label: "South-West" },
];




export const statesListData = [
  { value: "Andhra Pradesh", label: "Andhra Pradesh" },
  { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
  { value: "Assam", label: "Assam" },
  { value: "Bihar", label: "Bihar" },
  { value: "Chhattisgarh", label: "Chhattisgarh" },
  { value: "Goa", label: "Goa" },
  { value: "Gujarat", label: "Gujarat" },
  { value: "Haryana", label: "Haryana" },
  { value: "Himachal Pradesh", label: "Himachal Pradesh" },
  { value: "Jharkhand", label: "Jharkhand" },
  { value: "Karnataka", label: "Karnataka" },
  { value: "Kerala", label: "Kerala" },
  { value: "Madhya Pradesh", label: "Madhya Pradesh" },
  { value: "Maharashtra", label: "Maharashtra" },
  { value: "Manipur", label: "Manipur" },
  { value: "Meghalaya", label: "Meghalaya" },
  { value: "Mizoram", label: "Mizoram" },
  { value: "Nagaland", label: "Nagaland" },
  { value: "Odisha", label: "Odisha" },
  { value: "Punjab", label: "Punjab" },
  { value: "Rajasthan", label: "Rajasthan" },
  { value: "Sikkim", label: "Sikkim" },
  { value: "Tamil Nadu", label: "Tamil Nadu" },
  { value: "Telangana", label: "Telangana" },
  { value: "Tripura", label: "Tripura" },
  { value: "Uttar Pradesh", label: "Uttar Pradesh" },
  { value: "Uttarakhand", label: "Uttarakhand" },
  { value: "West Bengal", label: "West Bengal" },
  { value: "Andaman and Nicobar Islands", label: "Andaman and Nicobar Islands" },
  { value: "Chandigarh", label: "Chandigarh" },
  { value: "Dadra and Nagar Haveli and Daman and Diu", label: "Dadra and Nagar Haveli and Daman and Diu" },
  { value: "Lakshadweep", label: "Lakshadweep" },
  { value: "Delhi", label: "Delhi" },
  { value: "Puducherry", label: "Puducherry" },
  { value: "Ladakh", label: "Ladakh" },
  { value: "Jammu and Kashmir", label: "Jammu and Kashmir" }
];

export const keysToDisplay = (category, saleType, pgSubtype) => {
  if (category.toLowerCase() === "House & Apartments".toLowerCase() && saleType.toLowerCase() === "sell") {
    return [
      "subCategoryType",
      "noofBedrooms",
      "noofBathrooms",
      "furnishing",
      "listedBy",
      "builtupArea",
      "carpetArea",
      "maintenance",
      "totalFloors",
      "floorNo",
      "carParking",
      "facing",
      "projectName",
      "name",
      "phoneNumber",
      "email",
      "projectStatus",
    ];
  } else if (category.toLowerCase() === "House & Apartments".toLowerCase() && (saleType.toLowerCase() === "rent" || saleType.toLowerCase() === "rent/lease")) {
    return [
      "subCategoryType",
      "noofBedrooms",
      "noofBathrooms",
      "furnishing",
      "listedBy",
      "builtupArea",
      "carpetArea",
      "maintenance",
      "totalFloors",
      "floorNo",
      "carParking",
      "facing",
      "projectName",
      "name",
      "phoneNumber",
      "email",
      "bachelorsAllowed",
    ];
  } else if (category.toLowerCase() === "Shops & Offices".toLowerCase() && saleType.toLowerCase() === "sell") {
    return [
      "projectName",
      "name",
      "phoneNumber",
      "email",
      "furnishing",
      "listedBy",
      "builtupArea",
      "carpetArea",
      "maintenance",
      "carParking",
      "washrooms",
      "projectStatus",
    ];
  } else if (category.toLowerCase() === "Shops & Offices".toLowerCase() && (saleType.toLowerCase() === "rent" || saleType.toLowerCase() === "rent/lease")) {
    return [
      "projectName",
      "name",
      "phoneNumber",
      "email",
      "furnishing",
      "listedBy",
      "builtupArea",
      "carpetArea",
      "maintenance",
      "carParking",
      "washrooms",
    ];
  } else if (category.toLowerCase() == "Lands & Plots".toLowerCase() && saleType.toLowerCase() === "sell") {
    return [
      "projectName",
      "name",
      "phoneNumber",
      "email",
      "listedBy",
      "ploatArea",
      "length",
      "breadth",
      "facing",
    ];
  } else if (category.toLowerCase() == "Lands & Plots".toLowerCase() &&(saleType.toLowerCase() === "rent" || saleType.toLowerCase() === "rent/lease")) {
    return [
      "projectName",
      "name",
      "phoneNumber",
      "email",
      "listedBy",
      "ploatArea",
      "length",
      "breadth",
      "facing",
    ];
  } else if (category.toLowerCase() == "PG & Guest Houses".toLowerCase() && pgSubtype == "Guest House") {
    return [
      "name",
      "phoneNumber",
      "email",
      "subCategoryType",
      "furnishing",
      "listedBy",
      "carParking",
      "mealsIncluded",
    ];
  } else if (category.toLowerCase() == "PG & Guest Houses".toLowerCase() && pgSubtype != "Guest House") {
    return [
      "name",
      "phoneNumber",
      "email",
      "subCategoryType",
      "furnishing",
      "listedBy",
      "carParking",
      "mealsIncluded",
      "roomSharing",
    ];
  }
};