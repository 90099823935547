import React from 'react'
import Cardcomponent from '../Components/cardcomponent'
import './agents.css'

export default function Agents() {
  return (
    <div className='agents_section'>
        {[...Array(6)].map((ele)=><Cardcomponent />)}
    </div>
  )
}
