import React,{useState, useEffect} from 'react';
import { ConfigProvider, Layout, Space, Table, Button} from 'antd';
import {MailOutlined} from "@ant-design/icons";
import { getContactUSlist } from '../Auth/settings';

const {Content} = Layout




const ContactUs = () => {

  const [contactUsList, setContactUsList] = useState([]);

  useEffect(() => {
    fetchContactUSlist()
    
  }, []);

  const fetchContactUSlist = async () => {
    const response = await getContactUSlist();
    if(response && response.contactus.length > 0) {
      setContactUsList(response.contactus);
    }
  }

      const columns = [
        {
          title: 'First Name',
          dataIndex: 'firstName',
          key: 'firstName',
        //   render: (text) => <a>{text}</a>,
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            // render: (text) => <a>{text}</a>,
          },
          {
            title: 'E-Mail',
            dataIndex: 'email',
            key: 'email',
            // render: (text) => <a>{text}</a>,
          },
        {
          title: 'Subject',
          dataIndex: 'subject',
          key: 'subject',
        },
        {
          title: 'Message',
          dataIndex: 'msg',
          key: 'msg',
          
        },
        
        {
          title: 'Action',
          key: 'action',
          render: (_, record) => (
            <Space size="middle">
          
          <Button type="link" shape="round" icon={<MailOutlined />} size={24} 
          onClick={()=>{
            window.location.href = `mailto:${record?.email}`;
          }}
          >
            Reply
          </Button>
            </Space>
          ),
        },
      ];
      const data = [
        {
          key: '1',
          firstName: 'John',
          lastName: "Brown",
          email: "test@example.com",
          subject: "Site",
          message: 'Lorem ipsum dolor sit amet',
         
        },
        {
          key: '2',
          firstName: 'John',
          lastName: "Smith",
           email: "test@example.com",
          subject: "Site",
          message: 'Lorem ipsum dolor sit amet',
         
        },
        {
            key: '3',
            firstName: 'Smit',
            lastName: "Brown",
             email: "test@example.com",
            subject: "Site",
            message: 'Lorem ipsum dolor sit amet Lorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit amet',
           
        },
        {
            key: '4',
            firstName: 'John',
            lastName: "Brown",
             email: "test@example.com",
            subject: "Site",
            message: 'Lorem ipsum dolor sit amet',
           
          },
          {
            key: '5',
            firstName: 'John',
            lastName: "Smith",
             email: "test@example.com",
            subject: "Site",
            message: 'Lorem ipsum dolor sit amet',
           
          },
          {
              key: '6',
              firstName: 'Smit',
              lastName: "Brown",
               email: "test@example.com",
              subject: "Site",
              message: 'Lorem ipsum dolor sit amet Lorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit amet',
             
          },
          {
            key: '7',
            firstName: 'John',
            lastName: "Brown",
             email: "test@example.com",
            subject: "Site",
            message: 'Lorem ipsum dolor sit amet',
           
          },
          {
            key: '8',
            firstName: 'John',
            lastName: "Smith",
             email: "test@example.com",
            subject: "Site",
            message: 'Lorem ipsum dolor sit amet',
           
          },
     
        

      ];

  return (
    <ConfigProvider 
    theme={{
        components: {
            Table:{
      
                headerBg:"#e7e7e7",
              
               
             }
        },
      }}
    >
      <Layout>
        <Content style={{ padding: "20px" }}>
        <Table columns={columns} dataSource={contactUsList} pagination={false} bordered />
        </Content>
        </Layout>
        </ConfigProvider>
  )
}

export default ContactUs