import React from "react";
import CustomFormRadioButton from "./CustomFormRadioButton";
import CustomFormNumberField from "./CustomFormNumberField";
import {
  FurnishedData,
  listedByData,
  projectStatusData,
} from "../../Dummy Data/dummyData";

const ShopsAndOfficesForm = ({
  furnished,
  saleType,
  projectStatus,
  listedBy,
  buildUpArea,
  carpetArea,
  monthlyMaintenance,
  carParking,
  washrooms,
  handleSelectedButtonType,
  handleValuesChange,
}) => {
  return (
    <>
      {/* Furnishing */}
      <CustomFormRadioButton
        data={FurnishedData}
        defaultValue={FurnishedData[0]}
        formLabel={"Furnishing"}
        selectedValue={furnished}
        size={"large"}
        formName={"furnishing"}
        handleChange={(event) => handleSelectedButtonType(event, "furnishing")}
      />

      {/* Project Status */}
      {saleType.toLowerCase() === "sell" && (
        <CustomFormRadioButton
          data={projectStatusData}
          defaultValue={projectStatusData[0]}
          formLabel={"Project Status"}
          selectedValue={projectStatus}
          size={"large"}
          formName={"projectStatus"}
          handleChange={(event) =>
            handleSelectedButtonType(event, "projectStatus")
          }
        />
      )}

      {/* Listed By */}
      <CustomFormRadioButton
        data={listedByData}
        defaultValue={listedByData[0]}
        formLabel={"Listed By"}
        selectedValue={listedBy}
        size={"large"}
        formName={"listedBy"}
        handleChange={(event) => handleSelectedButtonType(event, "listedBy")}
      />

      {/* Super Builtup area */}
      <CustomFormNumberField
        label={"Super Builtup area sqft"}
        formName={"builtupArea"}
        required={true}
        inputType={"number"}
        placeholder={"Please enter the super built up area."}
        errorMessage={"Super Builup area sqft is mandatory."}
        size={"large"}
        onChange={(value) => handleValuesChange(value, "superBuiltUpArea")}
        value={buildUpArea}
        maxNumber={10000000}
      />

      {/* Carpet Area */}
      <CustomFormNumberField
        label={"Carpet Area sqft"}
        required={true}
        formName={"carpetArea"}
        inputType={"number"}
        placeholder={"Please enter the carpet area."}
        errorMessage={"Carpet Area sqft is mandatory."}
        size={"large"}
        onChange={(value) => handleValuesChange(value, "carpetArea")}
        value={carpetArea}
        maxNumber={10000000}
      />

      {/* Maintenance (Monthly) */}
      <CustomFormNumberField
        label={"Maintenance (Monthly)"}
        required={false}
        inputType={"number"}
        placeholder={"Please enter the amount."}
        errorMessage={""}
        size={"large"}
        formName={"maintenance"}
        onChange={(value) => handleValuesChange(value, "monthlyMaintenance")}
        value={monthlyMaintenance}
        maxNumber={10000000}
      />

      {/* Car Parking */}
      <CustomFormRadioButton
        data={['0', '1', '2', '3', "3+"]}
        defaultValue={'1'}
        formLabel={"Car Parking"}
        formName={"carParking"}
        selectedValue={carParking}
        size={"large"}
        handleChange={(event) => handleSelectedButtonType(event, "carParking")}
      />

      {/* Washrooms */}
      <CustomFormNumberField
        label={"Washrooms"}
        required={false}
        inputType={"number"}
        formName={"washrooms"}
        placeholder={"Please enter number."}
        errorMessage={""}
        size={"large"}
        onChange={(value) => handleValuesChange(value, "washrooms")}
        value={washrooms}
        maxNumber={10000000}
      />
    </>
  );
};

export default ShopsAndOfficesForm;
