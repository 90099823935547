import React from 'react'
import { Form, Select } from "antd";

const CustomFormSelectDropDown = ({label, required, message, handleChange, data, defaultValue, value, name}) =>  {
  return (
            <Form.Item
                label={label}
                name={name}
                rules={[{ required: required, message: message }]}
                initialValue={value}
            >
                <Select
                        defaultValue={defaultValue}
                        style={{ width: "100%", }}
                        onChange={handleChange}
                        options={data}
                        value={value}
                        />
            </Form.Item>
  )
}

export default CustomFormSelectDropDown