import React, { useState, useEffect } from "react";
import {
  ConfigProvider,
  Layout,
  Grid,
  Row,
  Col,
  Card,
  Avatar,
  Button,
  Empty,
  Space,
  Typography,
  Badge,
  Flex,
  Tag,
  Tooltip,
  Popconfirm,
  message,
} from "antd";
import { getCategoryPostsApi, deleteCategoryPostsApi } from "../Auth/properties";
import NoImgFound from "../assets/noimg.png";
import DetailSite from "./DetailSite";
import {
  EyeFilled,
  EditFilled,
  DeleteFilled,
  IdcardFilled,
} from "@ant-design/icons";
import SiteInfo from "./SiteInfo";
import {keysToDisplay} from "../Dummy Data/dummyData"
import { useNavigate } from "react-router-dom";

const { Content } = Layout;
const { useBreakpoint } = Grid;
const { Meta } = Card;
const { Text } = Typography;

const Sites = () => {
  const [isList, setIsList] = useState(true);
  const [activePropertie, setActivePropertie] = useState({});
  const [listofImages, setListofImages] = useState([]);
  const screens = useBreakpoint();
  const navigate = useNavigate();
  useEffect(() => {
    getAllProp();
  }, [isList]);

  const getAllProp = async () => {
    const response = await getCategoryPostsApi();
    if (response) {
      setListofImages(response.properties);
      console.log(response.properties);
    }
  };

  const confirm = async (e) => {
 
    const response = await deleteCategoryPostsApi({id: e?._id});
    if (response) {
      getAllProp()
      message.success("Delete Property successfully");
    } else {
      message.error("Request failed");
    }
  };
  const cancel = (e) => {
    console.log(e);
    // message.error('Cancel Delete Request');
  };

  function excludeKeysAndReturnKeyValues(obj, keysToExclude) {
    return Object.entries(obj)
        .filter(([key, value]) => keysToExclude?.includes(key) && value !==undefined && value !=="" && value !==null)
        .map(([key, value]) => ({ key, value }));
}




  return (
    <ConfigProvider
      theme={{
        components: {
          Card:{
            
          }
        },
      }}
    >
      <Layout>
        <Content style={{ padding: "20px" }}>
          {isList ? (
            listofImages.length > 0 ? (
              <Row gutter={[16, 16]} justify={"space-between"}>
                {listofImages.map((ele, index) => {
                  if (ele.postedBy.role === 0) {
                    
                    return (
                      <Col
                        key={index}
                        xs={24}
                        sm={24}
                        md={12}
                        lg={10}
                        xl={8}
                        xxl={6}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Badge.Ribbon
                          style={{
                            fontWeight: "600",
                            display: ele?.isRejected
                              ? "flex"
                              : ele?.verifiedByMMP
                              ? "flex"
                              : "none",
                          }}
                          text={
                            ele?.isRejected
                              ? "Rejected"
                              : ele?.verifiedByMMP
                              ? "MMP Verified"
                              : ""
                          }
                          color={ele?.isRejected ? "red" : "green"}
                        >
                          <Card
                            bordered={true}
                            hoverable={true}
                            style={{
                              height: "100%",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                            }}
                            cover={
                              <img
                                style={{
                                  width: "100%",
                                  height: 200,
                                  objectFit: "cover",
                                }}
                                alt="example"
                                src={
                                  ele?.imageUrl.length > 0
                                    ? ele?.imageUrl[0].split("?")[0]
                                    : NoImgFound
                                }
                              />
                            }
                            actions={[
                              <Tooltip
                                placement="bottom"
                                title={"View Details"}
                                color="#87d068"
                                arrow={{ pointAtCenter: true }}
                              >
                                <EyeFilled
                                  key="view"
                                  style={{ fontSize: 24, color: "#87d068" }}
                                  onClick={() => {
                                    setActivePropertie(ele);
                                    setIsList(false);
                                  }}
                                />
                              </Tooltip>,
                              <Tooltip
                                placement="bottom"
                                title={"User Profile"}
                                color="#108ee9"
                                arrow={{ pointAtCenter: true }}
                              >
                                <IdcardFilled
                                  key="profile"
                                  style={{ fontSize: 24, color: "#108ee9" }}
                                />
                              </Tooltip>,
                              <Tooltip
                                placement="bottom"
                                title={"Edit"}
                                color="orange"
                                arrow={{ pointAtCenter: true }}
                              >
                                <EditFilled
                                  key="edit"
                                  style={{ fontSize: 24, color: "orange" }}
                                  onClick={() => {
                                    navigate('/add-new-property', {state: {data:ele, isEdit:true}})
                                    // alert(JSON.stringify(ele._id))
                                    // setActivePropertie(ele);
                                    // setIsList(true);
                                  }}
                                />
                              </Tooltip>,
                              <Popconfirm
                                title="Delete the Admin Site"
                                description="Are you sure to delete this site?"
                                onConfirm={()=>confirm(ele)}
                                onCancel={cancel}
                                okText="Delete"
                                cancelText="Cancel"
                                icon={<DeleteFilled style={{ color: "red" }} />}
                                placement="top"
                              >
                                <Tooltip
                                  placement="bottom"
                                  title={"Delete"}
                                  color="#FF4D4F"
                                  arrow={{ pointAtCenter: true }}
                                >
                                  <DeleteFilled
                                    key="delete"
                                    style={{ fontSize: 24, color: "#FF4D4F" }}
                                  />
                                </Tooltip>
                              </Popconfirm>,
                            ]}
                          >
                            <div style={{ height: "100%" }}>
                            <Space
                                direction="horizontal"
                                size={8}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexWrap: "wrap",
                                  lineHeight: "1.2",
                                }}
                              >
                                <Text strong style={{ fontWeight: "bold", fontSize:18 }}>
                                  {ele?.title}
                                </Text>

                               

                              </Space>

                              <Space
                                direction="horizontal"
                                size={8}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexWrap: "wrap",
                                  lineHeight: "1.2",
                                }}
                              >
                                <Text  style={{  }}>
                                  {ele?.categoryType}
                                </Text>

                                <Space
                                  align="baseline"
                                  style={{ display: "flex" }}
                                >
                                  <Badge
                                    color="red"
                                    style={{
                                      height: 7,
                                      width: 7,
                                      borderRadius: "50%",
                                    }}
                                  />
                                  <Text style={{ fontSize: 14 }}>
                                    For {ele?.lookingTo}
                                  </Text>
                                </Space>

                              </Space>

                          
                              <Space
                                style={{
                                  margin: "10px 0",
                                  color: "#787878",
                                  flexWrap: "wrap",
                                  lineHeight: "1.2",
                                }}
                              >
                                <Text
                                  style={{
                                    whiteSpace: "break-spaces",
                                    overflow: "",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {ele?.address}{", "} {ele?.state}
                                </Text>
                              </Space>

                              <Flex gap="4px 0" wrap>
                                {excludeKeysAndReturnKeyValues(ele, keysToDisplay(ele?.categoryType, ele?.lookingTo, ele?.subCategoryType || undefined)).slice(0,5).map((item) => (
                                  <Tag
                                    color="#FF4D4F"
                                    style={{
                                      borderRadius: 10,
                                      fontWeight: "bold",
                                      color: "#fff",
                                      textTransform:"capitalize"
                                    }}
                                  >
                                    {item?.key}: {typeof item?.value === 'boolean' ?  item?.value ? "Yes" : "No" : item?.value}
                                  </Tag>
                                ))}
                              </Flex>

                              <Space
                                direction="horizontal"
                                size="large"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  margin: "10px 0",
                                  flexWrap: "wrap",
                                }}
                              >
                                <Text>₹{ele?.setPrice}</Text>
                                
                                {/* <Text>{ele?.builtupArea} -{ele?.carpetArea} sqft</Text> */}
                                <Text>{ele?.listedBy}</Text>
                              </Space>
                            </div>
                          </Card>
                        </Badge.Ribbon>
                      </Col>
                    );
                  }
                })}
              </Row>
            ) : (
              <Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{
                  height: 60,
                }}
              />
            )
          ) : (
            <SiteInfo property={activePropertie} setIsList={setIsList} />
          )}
        </Content>
      </Layout>
    </ConfigProvider>
  );
};

export default Sites;
