import React from "react";
import CustomFormRadioButton from "./CustomFormRadioButton";
import { FurnishedData, listedByData } from "../../Dummy Data/dummyData";
import {Form, Radio} from 'antd'
const PGAndGuestHousesForm = ({
  pgSubType,
  furnished,
  listedBy,
  carParking,
  isMealsIncluded,
  handleSelectedButtonType,
  roomSharing
}) => {
  return (
    <>
      {/* SubType */}
      <CustomFormRadioButton
        data={["Guest House", "PG", "Roommate"]}
        defaultValue={"Guest House"}
        formLabel={"Sub Type"}
        selectedValue={pgSubType}
        size={"large"}
        formName={"subCategoryType"}
        handleChange={(event) => handleSelectedButtonType(event, "pgSubType")}
      />

      {/* Furnishing */}
      <CustomFormRadioButton
        data={FurnishedData}
        defaultValue={FurnishedData[0]}
        formLabel={"Furnishing"}
        selectedValue={furnished}
        formName={"furnishing"}
        size={"large"}
        handleChange={(event) => handleSelectedButtonType(event, "furnishing")}
      />

      {/* Listed By */}
      <CustomFormRadioButton
        data={listedByData}
        defaultValue={listedByData[0]}
        formLabel={"Listed By"}
        selectedValue={listedBy}
        size={"large"}
        formName={"listedBy"}
        handleChange={(event) => handleSelectedButtonType(event, "listedBy")}
      />

      {/* Car Parking */}
      <CustomFormRadioButton
        data={['0', '1', '2', '3', "3+"]}
        defaultValue={'1'}
        formLabel={"Car Parking"}
        selectedValue={carParking}
        size={"large"}
        formName={"carParking"}
        handleChange={(event) => handleSelectedButtonType(event, "carParking")}
      />

{pgSubType.toLowerCase() !== "Guest House".toLowerCase() && <CustomFormRadioButton
        data={['0', '1', '2', '3', '4', '5', "5+"]}
        defaultValue={'1'}
        formLabel={"Room Sharing"}
        selectedValue={roomSharing}
        size={"large"}
        formName={"roomSharing"}
        handleChange={(event) => handleSelectedButtonType(event, "roomSharing")}
      />}

      {/* Meals Included */}
<Form.Item label={'Meals Included'} name={'mealsIncluded'} initialValue={isMealsIncluded}>
            <Radio.Group
            value={isMealsIncluded}
            // defaultValue={false}
            onChange={(event) =>
              handleSelectedButtonType(event, "isMealsIncluded")
            }
            size={'large'}
            buttonStyle="solid"
            style={{ display: "flex",}}
            >
            <Radio.Button style={{height:"auto",}} value={false} >No</Radio.Button>
            <Radio.Button style={{height:"auto",}} value={true} >Yes</Radio.Button>
            </Radio.Group>
            </Form.Item> 
    </>
  );
};

export default PGAndGuestHousesForm;
