import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import "./addproperties.css";
import {
  Button,
  Cascader,
  Checkbox,
  Form,
  Input,
  Radio,
  Select,
  Slider,
  TreeSelect,
  Upload,
  Image,
  notification
} from "antd";
import UploadImg from "../assets/uploaddetails.png";
import AWS from "aws-sdk";
import UploadImgComp from "./UploadImgComp";
import {addPropertiesApi } from '../Auth/properties'

const { TextArea } = Input;

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const options = [];
for (let i = 10; i < 36; i++) {
  options.push({
    value: i.toString(36) + i,
    label: i.toString(36) + i,
  });
}

const Addproperties = () => {

  const [value, setValue] = useState(1);
  const [propertyType, setPropertyType] = useState('')
  const [isLoading,setIsLoading] = useState(false)
  const [checkVerifiedByMMP,setCheckVerifiedByMMP] = useState(false)
  const [rangevalue, setRangeValue] = useState([0, 50000000]); // Initial range: 0.0 lakhs to 5.0 crores
  const [formPrice,setPrice] = useState()
  const [formArea,setArea] = useState('')
  const [formPlotArea,setPlotArea] = useState('')
  const [formConstructionStatus,setConstructionStatus] = useState('')
  const [formFurnishing,setFurnishing] = useState('')
  const [formDescription,setDescription] = useState('')

  const [listofImages, setListofImages] = useState([
    {id:1, title: "Cover photo", name: "coverPic", imgurl: UploadImg},
    {id:2, title: "Exterior", name: "exteriorPic",imgurl: UploadImg },
    {id:3, title: "Interior", name: "interiorPic",imgurl: UploadImg },
    {id:4, title: "Floor Plan", name: "floorPlanPic",imgurl: UploadImg },
  ]);

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type,head,content) => {
    api[type]({
      message: head,
      description:content,
    });
  };

  const handleSliderChange = (newValue) => {
    // Round the values to 2 decimal places
    const roundedValue =
      typeof newValue === "number"
        ? Math.round(newValue * 100) / 100
        : newValue.map((val) => Math.round(val * 100) / 100);
    setRangeValue(roundedValue);
  };

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const S3_BUCKET = "mmp-upload";
  const REGION = "ap-south-1";

  const handleImageUpload = async () => {
    try {
      const s3 = new AWS.S3({
        accessKeyId: "AKIAXTORPZNUUHTHTDUK",
        secretAccessKey: "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",
        region: REGION,
      });

      const promises = listofImages.map(async (image) => {
        const params = {
          Bucket:S3_BUCKET,
          Key: `images/${image.filename.name}`,
          Body: image.filename
        };

        const data = await s3.upload(params).promise();
        return data.Location;
      });

      const uploadedImageUrls = await Promise.all(promises);
      return uploadedImageUrls;

      // console.log("Image uploaded successfully:", data);
    } catch (error) {
      console.error("Error uploading image:", error);
      return []
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('eeeee',)
    try {
      setIsLoading(true)
      let uploadedImgs = await handleImageUpload();
      if(uploadedImgs?.length >0 ){
        const propertyTypeLength = propertyType.length;
        // const rangevalueLength = rangevalue.length;
        const formPriceLength = formPrice > 0 ? 4 : 0;
        const formAreaLength = formArea.length;
        const formPlotAreaLength = formPlotArea.length;
        const formConstructionStatusLength = formConstructionStatus.length;
        const formFurnishingLength = formFurnishing.length;
        const formDescriptionLength = formDescription.length;

        if (propertyTypeLength > 1 && formPriceLength > 1 && formAreaLength > 1 && formPlotAreaLength > 1 && formConstructionStatusLength > 1 && formFurnishingLength > 1 && formDescriptionLength > 1) {

          const response = await addPropertiesApi({
            propertyType: propertyType,
            budgetRange:'0 to 5000000',
            // budgetRange:rangevalue?.join(' to '),
            price: formPrice,
            area: formArea,
            plotArea: formPlotArea,
            constructionStatus: formConstructionStatus,
            furnishing: formFurnishing,
            description: formDescription,
            lookingto: value == 1 ? "sell" : value == 2 ? "rent" : "lease",
            verifiedByMMP: checkVerifiedByMMP,
            floorPlanPic: uploadedImgs[3],
            interiorPic: uploadedImgs[2],
            exteriorPic: uploadedImgs[1],
            coverPic: uploadedImgs[0],
            postedBy:JSON.parse(localStorage?.getItem('userDetails'))?.user?._id
          })
          if(response){
            let signres = response?.err;

            openNotificationWithIcon(signres ? 'error':'success',signres ? 'Error':'Success',response?.msg)
            setValue('')
            setPropertyType('')
            setCheckVerifiedByMMP(false)
            setRangeValue([0, 50000000])
            setPrice(0)
            setArea('')
            setPlotArea('')
            setConstructionStatus('')
            setFurnishing('')
            setDescription('')
            setListofImages([
              {id:1, title: "Cover photo", name: "coverPic", imgurl: UploadImg},
              {id:2, title: "Exterior", name: "exteriorPic",imgurl: UploadImg },
              {id:3, title: "Interior", name: "interiorPic",imgurl: UploadImg },
              {id:4, title: "Floor Plan", name: "floorPlanPic",imgurl: UploadImg },
            ])

          }else{
            console.log('error in response')
            openNotificationWithIcon('error','Error','Please try again after sometime')
          }
          // openNotificationWithIcon('success','Success','Uploading images failed try again after sometime')
        }else{
          // !At least one state has a length greater than 0
        openNotificationWithIcon('error','Error','All Fields are Mandatory!')
        console.log('error in all Fields')
        }

      }else{
        openNotificationWithIcon('error','Error','All Images are Mandatory!')
        console.log('error in all Images')
      }
      setIsLoading(false)

    } catch (error) {
      setIsLoading(false)
      console.error("Error uploading images:", error);
      openNotificationWithIcon('error','Error','Please try again after sometime')
    }
  };

  const marks = {
    0: "0.0L",
    50000000: "5.0Cr+",
  };

  const tipFormatter = (value) => {
    const roundedValue = Math.round(value * 100) / 100;

    if (roundedValue >= 10000000) {
      return `${(roundedValue / 10000000).toFixed(2)} Crs`;
    } else {
      return `${(roundedValue / 100000).toFixed(2)} L`;
    }
  };

  return (
    <div className="add-properties-form">
      {contextHolder}

      <form
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="vertical"
        style={{
          maxWidth: 700,
        }}
        // onFinish={handleSubmit}
        onSubmit={handleSubmit}
      >
        <div className="addproperties-row1">
          <div>
            <h5>Looking to</h5>
            <Radio.Group onChange={onChange} value={value} name='lookingto'>
              <Radio value={1}>Sell</Radio>
              <Radio value={2}>Rent</Radio>
              <Radio value={3}>Lease</Radio>
            </Radio.Group>
          </div>

          <div>
            <h5>Property type</h5>
            <Input style={{ width: 190 }} name='propertyType' onChange={(e)=>setPropertyType(e.target.value)} value={propertyType} />
            {/* <Select
                // size={size}
                defaultValue="a1"
                onChange={handleChange}
                style={{
                  width: 200,
                }}
                options={options}
              /> */}
          </div>
        </div>

        <div className="add-properties_upload-cover-img">
          {listofImages.map((ele) => {
            return (
              <div key={ele.id} style={{ margin: "0 10px" }}>
                <h5>{ele.title}</h5>
                <UploadImgComp imgData={{...ele}} listofImages={listofImages} setListofImages={setListofImages} />
              </div>
            );
          })}
        </div>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div style={{ width: 200, marginRight: 20 }}>
            <h5>Budget Range</h5>
            <Form.Item>
              <Slider
                min={0}
                max={50000000}
                value={rangevalue}
                onChange={handleSliderChange}
                tipFormatter={tipFormatter}
                marks={marks}
                style={{ width: 190 }}
                name='budgetRange'
              />
            </Form.Item>
          </div>

          <div style={{ width: 200, marginRight: 20 }}>
            <h5>Enter Price</h5>
            <Form.Item>
              <Input style={{ width: 190 }} type='number' name='price' onChange={(e)=>setPrice(e.target.value)} value={formPrice} />
            </Form.Item>
          </div>

          <div style={{ width: 200, marginRight: 20 }}>
            <h5>Area</h5>
            <Form.Item>
              <Input style={{ width: 190 }} name='area' onChange={(e)=>setArea(e.target.value)} value={formArea} />
              {/* <Select style={{width:190}}>
              <Select.Option value="demo">Demo</Select.Option>
            </Select> */}
            </Form.Item>
          </div>

          <div style={{ width: 200, marginRight: 20 }}>
            <h5>Plot Area</h5>
            <Form.Item>
              <Input style={{ width: 190 }} name='poltArea' onChange={(e)=>setPlotArea(e.target.value)} value={formPlotArea} />
              {/* <TreeSelect style={{width:190}}
              treeData={[
                {
                  title: 'Light',
                  value: 'light',
                  children: [
                    {
                      title: 'Bamboo',
                      value: 'bamboo',
                    },
                  ],
                },
              ]}
            /> */}
            </Form.Item>
          </div>

          <div style={{ width: 200, marginRight: 20 }}>
            <h5>Construction status</h5>
            <Form.Item>
              <Input style={{ width: 190 }} name='constructionStatus' onChange={(e)=>setConstructionStatus(e.target.value)} value={formConstructionStatus} />
              {/* <TreeSelect style={{width:190}}
              treeData={[
                {
                  title: 'Light',
                  value: 'light',
                  children: [
                    {
                      title: 'Bamboo',
                      value: 'bamboo',
                    },
                  ],
                },
              ]}
            /> */}
            </Form.Item>
          </div>

          <div>
            <Form.Item>
              <h5>Furnishing</h5>
              <Input style={{ width: 190 }} name='furnishing' onChange={(e)=>setFurnishing(e.target.value)} value={formFurnishing} />
              {/* <Cascader style={{width:190}}
              options={[
                {
                  value: 'zhejiang',
                  label: 'Zhejiang',
                  children: [
                    {
                      value: 'hangzhou',
                      label: 'Hangzhou',
                    },
                  ],
                },
              ]}
            /> */}
            </Form.Item>
          </div>
        </div>

        <Form.Item>
          <h5>Description</h5>
          {/* <Input.TextArea style={{ width: 900 }} /> */}
          <TextArea rows={4} name='description' onChange={(e)=>setDescription(e.target.value)} value={formDescription} />
        </Form.Item>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Checkbox
            checked={checkVerifiedByMMP}
            onChange={(e) => setCheckVerifiedByMMP(e.target.checked)}
            >
              Verified by MMP
            </Checkbox>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button type="primary"
                htmlType="submit"
            loading={isLoading} disabled={isLoading}
            //  onClick={() => handleSubmit()}
             >
              Post Property
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default Addproperties;
