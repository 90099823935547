import React, {useState, useEffect, } from 'react';
import { Layout, Flex, Menu, ConfigProvider, Avatar, Space, Typography, Grid, Button, Breadcrumb, theme, Badge,  } from 'antd';
import {
  DownOutlined,
  UpOutlined,
  BranchesOutlined,
  LogoutOutlined,
  SettingOutlined,
  ScheduleOutlined,
  UsergroupAddOutlined,
  HomeOutlined,
  DesktopOutlined,
  ShopOutlined,
  RightOutlined,
  LeftOutlined,
  SearchOutlined,
  BellOutlined,
  MessageOutlined,
  BellFilled,
  QuestionCircleOutlined,
  PhoneOutlined,
  DeleteOutlined,
  UserOutlined,
  TeamOutlined,
  FileSearchOutlined
} from '@ant-design/icons';
import Addproperties from "../AddProperties/addproperties";
import Settings from "../Settings/settings";
import Agents from "../Agents/agents";
import Subscribedsites from "../Subscribedsites/Subscribedsites";
import Sites from "../Sites/Sites";
import AdminSites from '../Sites/AdminSites';
import Plans from "../Plans/Plan";
import BannerImg from "../assets/banner.png";
import { isAuthenticated } from "../Auth/auth";
import DashboardInfo from './DashboardInfo'
import AddNewProperty from '../AddProperties/AddNewProperty';
import NewProperty from '../AddProperties/NewProperty';
import ContactUs from '../Settings/ContactUs';
import DeleteAccount from '../Settings/DeleteAccount';
import FAQs from '../Settings/FAQs';
import Inspection from '../Settings/Inspection';

const { Header, Sider, Content, Footer } = Layout
const {useBreakpoint} = Grid

const userData = isAuthenticated();

const headerStyle = {
 
  display: 'flex',
  justifyContent: 'space-between', 
  
  alignItems: 'center',           
  color: '#fff',
  height: 64,
  padding: '0 96px',    
         
   
};
const contentStyle = {
  textAlign: 'center',
  minHeight: 120,
  lineHeight: '120px',
  color: '#fff',
  backgroundColor: '#0958d9',
};
const siderStyle = {
  color: '#f4f1f0',
  backgroundColor: '#f4f1f0',
  marginRight: 16,
  position: "relative",

};
const footerStyle = {
  textAlign: 'center',
  color: '#fff',
  margin:0,
  marginTop:8,
  padding:0,
  
  
};


const collapsibleButtonStyle = {
  position: "absolute",
  top: "3%",
  right: "-10px",
  transform: "translateY(-3%)",
  backgroundColor: '#f4f1f0',
  color:'#3375e3',
  border: "none",
  borderRadius: "4px",
  padding: "45px 4px",
  cursor: "pointer",
  zIndex: 9,
  
};

const Dashboard = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [activeKey, setActiveKey] = useState("1");
  const [activeName, setActiveName] = useState("Dashboard");
  const [propertyDataForUpdate, setPropertyDataForUpdate] = useState(null);
  const screens = useBreakpoint()

  const calculateSiderWidth = () => {
    if (screens.lg) return 300;
    if (screens.md) return 250;
    return 250; 
  };

 
  useEffect(() => {
    if (!screens.sm && !screens.md && !screens.sm) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [screens]);


  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const checkIsSubMenu = () =>{
    if(['8','9','10', '11','12', '13',].includes(activeKey)){
      return true
    }else{
      return false
    }
  }
  if(false){
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgBase: "#fff",
        },
        components: {
          Layout: {
            // colorBgHeader: "#fff", //depricated error in logs
            headerBg: "#fff",
            colorBgLayout: '#fff'
          },
          Slider: {},
          Menu: {
            fontSize: 14,
            // colorItemBg: "#fff",//depricated error in logs
            itemBg:"#fff"
          },
        },

      }}
    >

      <Flex gap="middle" wrap style={{ height: "100vh", }} >
        <Layout style={{ borderRadius: 8, margin: screens.md ? "16px 32px" : "4px 2px",}} >
          <Sider
        
            style={siderStyle}
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          trigger={null}
          width={calculateSiderWidth()}
          
          >
             <Button
            onClick={() => setCollapsed(!collapsed)}
            style={ {display:screens.xs ? "none": "flex",...collapsibleButtonStyle}}
            
          >
            {collapsed ? <RightOutlined /> : <LeftOutlined />}
          </Button>
            <div style={{ backgroundColor: "#f4f1f0", height: "100%", borderRadius: 20,}}>
              
          
       <Flex vertical >
              <Flex justify='center' style={{ marginLeft: 16, marginRight: 16 }} >
                <Avatar shape="square" size={48} src={BannerImg} style={{ marginTop: 32 }} />
              </Flex>

              <Flex justify='space-between' align='center' gap={"middle"} style={{ marginTop: 32, marginLeft: 16, marginRight: 16 }} >
                <Avatar shape="circle"
                  style={{ backgroundColor: '#ff0000', }}
                  size={'large'} src={"https://api.dicebear.com/7.x/miniavs/svg?ßseed=8"} />

              {!collapsed &&  <Typography style={{ verticalAlign: 'middle', textAlign: "center", fontFamily: "sans-serif", fontWeight: "bold", fontSize: 14 }}>{userData?.user ? userData?.user.username : "Admin"}</Typography>}

                {!collapsed && <Avatar shape="square"
                  style={{
                    backgroundColor: '#3375e3',
                    verticalAlign: 'middle',
                  }}
                  size={24} icon={< DownOutlined />} />}
              </Flex>

            
                <Menu

                  style={{ backgroundColor: "#f4f1f0", border: "none", fontWeight: "600", fontFamily: "sans-serif", marginTop: 32,}}
                  mode="inline"
                  defaultSelectedKeys={['1']}
                  items={[
                    {
                      key: '1',
                      icon: <BranchesOutlined size={"large"} style={{ marginRight: 16, color: "#4096ff" }} />,
                      label: 'Dashboard',
                      style: { marginTop: 16, },
                      itemIcon:()=>(<RightOutlined style={{marginRight: 16, color: "#4096ff" }} />) ,    
                      onClick: () => {
                        setActiveKey("1");
                        setActiveName("Dashboard");
                      }
                    },
                    {
                      key: '2',
                      icon: <ShopOutlined style={{ marginRight: 16, color: "#4096ff" }} />,
                      label: 'Site',


                      children: [
                        {
                          key: '12',
                          label: 'User Sites',
                          onClick: () => {
                            setActiveKey("12");
                            setActiveName("User Sites");
                          },
                          icon: <TeamOutlined />
                        },
                        {
                          key: '13',
                          label: 'Admin Sites',
                          onClick: () => {
                            setActiveKey("13");
                            setActiveName("Admin Sites");
                          },
                          icon: <UserOutlined />
                        },
                      ],
                      
                      expandIcon: ({ isOpen }) => (isOpen ? <DownOutlined style={{ justifyContent:"flex-end",color: "#4096ff" }} /> : <RightOutlined  style={{ justifyContent:"flex-end", color: "#4096ff" }} />),
                    },
                    {
                      key: '3',
                      icon: <DesktopOutlined style={{ marginRight: 16, color: "#4096ff" }} />,
                      label: 'Subscibed Site',
                      itemIcon:()=>(<RightOutlined  style={{marginRight: 16, color: "#4096ff" }} />) ,  
                      onClick: () => {
                        setActiveKey("3");
                        setActiveName("Subscibed Site");
                      }
                    },
                    {
                      key: '4',
                      icon: <HomeOutlined style={{ marginRight: 16, color: "#4096ff" }} />,
                      label: 'Add Property',
                      itemIcon:()=>(<RightOutlined style={{marginRight: 16, color: "#4096ff" }} />) ,  
                      onClick: () => {
                        setActiveKey("4");
                        setActiveName("Add Property");
                      }
                    },
                    {
                      key: '5',
                      icon: <UsergroupAddOutlined style={{ marginRight: 16, color: "#4096ff" }} />,
                      label: 'Agents',
                      itemIcon:()=>(<RightOutlined style={{marginRight: 16, color: "#4096ff" }} />) ,  
                      onClick: () => {
                        setActiveKey("5");
                        setActiveName("Agents");
                      }
                    },
                    {
                      key: '6',
                      icon: <ScheduleOutlined style={{ marginRight: 16, color: "#4096ff" }} />,
                      label: 'Plans',
                      itemIcon:()=>(<RightOutlined style={{marginRight: 16, color: "#4096ff" }} />) ,  
                      onClick: () => {
                        setActiveKey("6");
                        setActiveName("Plans");
                      }
                    },
                    // {
                    //   key: '7',
                    //   icon: <SettingOutlined style={{ marginRight: 16, color: "#4096ff" }} />,
                    //   label: 'Settings',
                    //   itemIcon:()=>(<RightOutlined style={{ marginRight: 16,color: "#4096ff" }} />) , 
                    //   onClick: () => {
                    //     setActiveKey("7");
                    //     setActiveName("Settings");
                    //   } 
                    // },
                    {
                      key: '7',
                      icon: <SettingOutlined  style={{ marginRight: 16, color: "#4096ff" }} />,
                      label: 'Settings',


                      children: [
                        {
                          key: '8',
                          label: 'Admin Profile',
                          onClick: () => {
                            setActiveKey("8");
                            setActiveName("Settings");
                          },
                          icon: <UserOutlined />
                        },
                        {
                          key: '9',
                          label: 'Delete Account',
                          onClick: () => {
                            setActiveKey("9");
                            setActiveName("Delete Account");
                          },
                          icon: <DeleteOutlined />
                        },
                        {
                          key: '10',
                          label: 'Contact Us',
                          onClick: () => {
                            setActiveKey("10");
                            setActiveName("Contact Us");
                          },
                          icon: <PhoneOutlined />
                        },
                        {
                          key: '11',
                          label: 'FAQs',
                          onClick: () => {
                            setActiveKey("11");
                            setActiveName("FAQs");
                          }, 
                          icon: <QuestionCircleOutlined />
                        },
                        {
                          key: '14',
                          label: 'Inspection',
                          onClick: () => {
                            setActiveKey("14");
                            setActiveName("Inspection");
                          }, 
                          icon: <FileSearchOutlined />
                        }
                      ],
                      
                      expandIcon: ({ isOpen }) => (isOpen ? <DownOutlined style={{ justifyContent:"flex-end",color: "#4096ff" }} /> : <RightOutlined  style={{ justifyContent:"flex-end", color: "#4096ff" }} />),
                    },
                  ]}
                /> 
             
                  <Button 
                  variant='link'
                  onClick={() => {
                  localStorage.clear();
                  window.location.reload();
                }} icon={<LogoutOutlined style={{color:"#f55555"}}/>} 
                style={{color:"#f45555", position:"absolute", bottom:32, left:"50%", transform:"translate(-50%)"}}
                color={"danger"}>{collapsed ? "" : "Logout"}</Button>
              </Flex>

            </div>
          </Sider>
          <Layout>


          <Header
      style={{
        display: 'flex',
        flexDirection: screens.md ? 'row' : 'column',
        justifyContent: screens.md ? 'space-between' : 'center',
        alignItems: 'center',
        color: '#fff',
        // height: 64,
        padding: screens.md ? '0 64px' : '48px 16px',
      }}
    >
      <Typography.Paragraph
        level={5}
        style={{
          margin: 0,
          overflow: 'visible',
          whiteSpace:"normal",
          fontSize: screens.md ? 16 : 14,
          fontWeight: '600',
          textAlign: screens.md ? 'left' : 'center',
        }}
      >
        Hello {userData?.user ? userData?.user.username : "Admin"}, Welcome back
      </Typography.Paragraph>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: screens.md ? '96px' : '16px',
          marginTop: screens.md ? 0 : '16px',
          width: screens.md ? "auto" :  "100%" ,
          justifyContent: screens.md ?  "flex-start" :"space-between"
        }}
      >
        <Badge
          count={<BellFilled style={{ color: '#3375e3', fontSize: 16 }} />}
          offset={[0, 0]}
        >
          <MessageOutlined style={{ color: '#747474', fontSize: 24 }} />
        </Badge>
        <SearchOutlined style={{ fontSize: 24, color: '#747474' }} />
      </div>
    </Header>
         


          <Content
            style={{
              margin: "0 16px",
              // background: "#f5f5f5",
            }}
          >
            <Breadcrumb
              style={{
                margin: "16px 0",
              }}
            >
              <Breadcrumb.Item>
                <div style={{ fontWeight: "600", marginLeft:12, fontFamily:"sans-serif", fontSize:14,}}>{checkIsSubMenu() ? activeKey === "12" || activeKey === "13"  ? "Sites" : "Settings" : activeName}</div>
            
              </Breadcrumb.Item>
              {checkIsSubMenu() && <Breadcrumb.Item> <div style={{ fontWeight: "600",  fontFamily:"sans-serif", fontSize:14, }}>{activeName}</div></Breadcrumb.Item> }
           

            </Breadcrumb>

            <div
              style={{
                padding: 24,
                minHeight: 360,
                // background: "#f5f5f5",
                // borderRadius: borderRadiusLG,
              }}
            >
              {activeKey == '1' && <DashboardInfo />}
              {activeKey === "4" && <AddNewProperty />}
              {activeKey === "5" && <Agents />}
              {activeKey === "12" && <Sites />}
              {activeKey === "13" && <AdminSites />}
              {activeKey === "6" && <Plans />}
              {/* {activeKey === "3" && <Subscribedsites />} */}
              {activeKey === "3" && <NewProperty />}
              {activeKey === "8" && <Settings />}
              {activeKey === "9" && <DeleteAccount/>}
              {activeKey === "10" && <ContactUs/>}
              {activeKey === "11" && <FAQs/>}
              {activeKey === "14" && <Inspection/>}
             
            </div>
          </Content>
        <Footer style={footerStyle}>
          <Typography.Paragraph style={{fontFamily:"sans-serif", fontSize:12,}}> MakeMake My Propertyz ©{new Date().getFullYear()}</Typography.Paragraph>
        </Footer>
      </Layout>
        </Layout>
      </Flex>

    </ConfigProvider>
  )}
  return (
    <DashboardInfo/>
  )
}

export default Dashboard