import { API } from './config';


// ---------------------------------------------------------------- FAQS ----------------------------------------------------------------
export const getAllfaqslist = () => {
    return fetch(`${API}/api/allfaqs`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
     .then((response) => {
        return response.json();
      })
     .catch((err) => {
        console.log(err);
        return err
      });
  };

  export const addNewFaq = (data) => {
    return fetch(`${API}/api/submit-faq`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        console.log(err);
        return err
      });
  };

  export const updateFaq = (data) => {
    return fetch(`${API}/api/update-faq`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        console.log(err);
        return err
      });
  };

  export const delteFaq = (data) => {
    return fetch(`${API}/api/delete-faq`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        console.log(err);
        return err
      });
  };

// ---------------------------------------------------------------- FAQS ----------------------------------------------------------------

// ---------------------------------------------------------------- CONTACT US ----------------------------------------------------------------

export const getContactUSlist = () => {
  return fetch(`${API}/api/list-contact`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
   .then((response) => {
      return response.json();
    })
   .catch((err) => {
      console.log(err);
      return err
    });
};

// ---------------------------------------------------------------- CONTACT US ----------------------------------------------------------------

// ---------------------------------------------------------------- INSPECTION ----------------------------------------------------------------

export const getInspectionlist = () => {
  return fetch(`${API}/api/properties/list-inspectionproperty`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
   .then((response) => {
      return response.json();
    })
   .catch((err) => {
      console.log(err);
      return err
    });
};

// ---------------------------------------------------------------- INSPECTION ----------------------------------------------------------------
