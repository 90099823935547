import React from "react";
import { Form, InputNumber } from "antd";


const CustomFormNumberField = ({
  label,
  required,
  errorMessage,
  inputType,
  placeholder,
  size,
  onChange,
  value,
  maxNumber,
  formName,
}) => {
  return (
    <Form.Item
      label={label}
      name={formName}
      rules={[{ required: required, message: errorMessage, }]}
    >
      <InputNumber
        min={0}
        size={size}
        style={{ width: "100%" }}
        type={inputType}
        placeholder={placeholder}
        max={maxNumber}
        onChange={onChange}
        value={value}
        stringMode
        controls={false}
        changeOnWheel={false}
      />
      
    </Form.Item>
  );
};

export default CustomFormNumberField;
