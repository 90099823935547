import { API } from './config';

// SIGN UP
export const signup = (user) => {
  return fetch(`${API}/api/signup`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      return err
    });
};

// LOG IN
export const signin = (user) => {
  return fetch(`${API}/api/signin`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      return err
    });
};

//TOKEN
export const authenticate = (data, next) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem('userToken', JSON.stringify(data));
    next();
  }
};

//Authenticated boolean
export const isAuthenticated = () => {
  if (typeof window == 'undefined') {
    return false;
  }
  if (localStorage.getItem('userDetails')) {
    return JSON.parse(localStorage.getItem('userDetails'));
  } else {
    return false;
  }
};
