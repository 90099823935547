import React, { useState, useEffect } from "react";
import {
  ConfigProvider,
  Flex,
  Layout,
  Radio,
  Select,
  Divider,
  Row,
  Col,
  Grid,
  Form,
  Button,
  InputNumber,
  notification,
  Spin,
  Space

} from "antd";
import { DollarOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  facingData,
  listedByData,
  saleCategoryData,
} from "../Dummy Data/dummyData";
import CustomFormTextField from "./Components/CustomFormTextField";
import CustomFormDescriptionField from "./Components/CustomFormDescription";
import FileUploader from "./Components/CustomImage";
import HouseAndApartmentsForm from "./Components/HouseAndApartmentsForm";
import LandAndPlotsForm from "./Components/LandAndPlotsForm";
import ShopsAndOfficesForm from "./Components/ShopsAndOfficesForm";
import PGAndGuestHousesForm from "./Components/PGAndGuestHousesForm";
import PersoalDetailsForm from "./Components/PersonalDetailsForm";
import { createCategoryPostApi,updateCategoryPostApi } from "../Auth/properties";
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const { Content } = Layout;
const { useBreakpoint } = Grid;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const AddNewProperty = () => {
  const screens = useBreakpoint();
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const {data, isEdit} = location.state || {}

  const [saleType, setSaleType] = useState("Sell");
  const [category, setCategory] = useState("House & Apartments");
  const [houseType, setHouseType] = useState("Flats/Apartments");
  const [bhk, setBHK] = useState('1');
  const [bathroom, setBathRoom] = useState('1');
  const [carParking, setCarParking] = useState('0');
  const [furnished, setFurnished] = useState("Furnished");
  const [projectStatus, setProjectStatus] = useState("New Launch");
  const [listedBy, setListedBy] = useState("Builder");
  const [buildUpArea, setBuildUpArea] = useState(null);
  const [carpetArea, setCarpetArea] = useState(null);
  const [isBachlorsAllowed, setIsBachlorsAllowed] = useState(false);
  const [monthlyMaintenance, setMonthlyMaintenance] = useState(null);
  const [washrooms, setWashrooms] = useState('1');
  const [totalFloors, setTotalFloors] = useState(null);
  const [floorNumber, setFloorNumber] = useState(null);
  const [facing, setFacing] = useState("North");
  const [projectName, setProjectName] = useState("");
  const [adTitle, setAdTitle] = useState("");
  const [adDescription, setAdDescription] = useState("");
  const [price, setPrice] = useState(null);
  const [plotArea, setPlotArea] = useState(null);
  const [plotLength, setPlotLength] = useState(null);
  const [plotBreadth, setPlotBreadth] = useState(null);
  const [pgSubType, setPgSubType] = useState("Guest House");
  const [isMealsIncluded, setIsMealsIncluded] = useState(false);
  const [checkVerifiedByMMP, setCheckVerifiedByMMP] = useState(true);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [state, setState] = useState(null);
  const [address, setAddress] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [roomSharing, setRoomSharing] = useState('0');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dataPrepopulation()
  }, [data, form]);


const dataPrepopulation = async () => {
  if (data) {
    //common fields
    
      setCategory(data?.categoryType);
      await setUploadedFiles(data?.imageUrl);
     if(data?.categoryType.toLowerCase() !== "PG & Guest Houses".toLowerCase()){
      await form.setFieldsValue({
        projectName: data?.projectName,
      });

      }
      await form.setFieldsValue({
        lookingTo: data?.lookingTo,
        categoryType: data?.categoryType,
        projectName: data?.projectName,
        title: data?.title,
        description: data?.description,
        setPrice: data?.setPrice,
        imageUrl: data?.imageUrl,
        name: data?.name,
        phoneNumber: data?.phoneNumber,
        email: data?.email,
        address: data?.address,
        state: data?.state,
        verifiedByMMP: data?.verifiedByMMP,
        
      });

      if(data?.categoryType.toLowerCase() === "House & Apartments".toLowerCase()) {
        if(data?.lookingTo.toLowerCase() === "rent" || data?.lookingTo.toLowerCase() === "rent/lease") {
          await form.setFieldsValue({
            bachelorsAllowed:data?.bachelorsAllowed
          })
        } else {
          await form.setFieldsValue({
            projectStatus: data?.projectStatus,
          })
        }
        editHouseAndApartments();
      } else if(data?.categoryType.toLowerCase() === "Lands & Plots".toLowerCase()) {
        editLandsAndPlots();
      } else if(data?.categoryType.toLowerCase() === "Shops & Offices".toLowerCase()) {
        if(data?.lookingTo.toLowerCase() === "Sell".toLowerCase()) {
         
          await form.setFieldsValue({
            projectStatus: data?.projectStatus,
          })
        }
        editShopsAndOffers()
      } else if(data?.categoryType.toLowerCase() === "PG & Guest Houses".toLowerCase()) {
        if(data?.subCategoryType.toLowerCase() !== "Guest House".toLowerCase()) {
         
          await form.setFieldsValue({
            roomSharing: data?.roomSharing,
          })
        }
        pGAndGuestHouses()
      }

   
  
  }
}

const editHouseAndApartments = async () => {
  await form.setFieldsValue({
    subCategoryType: data?.subCategoryType,
    noofBedrooms: data?.noofBedrooms,

    noofBathrooms: data?.noofBathrooms,
    furnishing: data?.furnishing,
   
    listedBy: data?.listedBy,
    builtupArea: data?.builtupArea,
    carpetArea: data?.carpetArea,
    maintenance: data?.maintenance,
    totalFloors: data?.totalFloors,
    floorNo: data?.floorNo,
    carParking: data?.carParking,
    facing: data?.facing,
  });
}

const editLandsAndPlots = async () => {
  await form.setFieldsValue({
    listedBy: data?.listedBy,
    ploatArea: data?.ploatArea,
    length: data?.length,
    breadth: data?.breadth,
    facing:data?.facing
  });
}

const editShopsAndOffers = async () => {
  await form.setFieldsValue({
  
    furnishing: data?.furnishing,
    listedBy: data?.listedBy,
    builtupArea: data?.builtupArea,
    carpetArea: data?.carpetArea,
    maintenance: data?.maintenance,
    carParking: data?.carParking,
    washrooms: data?.washrooms,
  });
}

const pGAndGuestHouses = async () => {
  await form.setFieldsValue({
    subCategoryType: data?.subCategoryType,
    furnishing: data?.furnishing,
    listedBy: data?.listedBy,
    carParking: data?.carParking,
    mealsIncluded:data?.mealsIncluded,
  })
}

useEffect(() => {
  
  if(!isEdit) {
    form.resetFields();
  }

}, [category]);
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type,head,content) => {
    api[type]({
      message: head,
      description:content,
    });
  };

  const handlePhoneNumberChange = (event) => {
      setPhoneNumber(event.target.value);
    
  };

  const handleFileUpload = (files) => {
    const imagesList = [...files, ...uploadedFiles];
    setUploadedFiles(imagesList);
  };

  const handleSaleType = (event) => {
    setSaleType(event.target.value);
  };

  const handleSelectCategory = (value) => {
    setCategory(value);

  };

  const handleSelectedButtonType = (event, type) => {
    const {
      target: { value },
    } = event;
    switch (type) {
      case "houseType":
        setHouseType(value);
        break;

      case "bhk":
        setBHK(value);
        break;

      case "bathrooms":
        setBathRoom(value);
        break;

      case "furnishing":
        setFurnished(value);
        break;

      case "projectStatus":
        setProjectStatus(value);
        break;

      case "listedBy":
        setListedBy(value);
        break;

      case "carParking":
        setCarParking(value);
        break;

      case "bachlorsAllowed":
        setIsBachlorsAllowed(value);
        break;

      case "pgSubType":
        setPgSubType(value);
        break;

      case "isMealsIncluded":
        setIsMealsIncluded(value);
        break;

      case "roomSharing":
        setRoomSharing(value);
    }
  };

  const handleValuesChange = (values, type) => {
    switch (type) {
      case "superBuiltUpArea":
        setBuildUpArea(values);
        break;

      case "carpetArea":
        setCarpetArea(values);
        break;

      case "monthlyMaintenance":
        setMonthlyMaintenance(values);
        break;

      case "totalFloors":
        setTotalFloors(values);
        break;

      case "floorNo":
        setFloorNumber(values);
        break;

      case "price":
        setPrice(values);
        break;

      case "plotArea":
        setPlotArea(values);
        break;

      case "plotLength":
        setPlotLength(values);
        break;

      case "plotBreadth":
        setPlotBreadth(values);
        break;

      case "washrooms":
        setWashrooms(values);

        case "state":
          setState(values);
          break;
          
    }
  };

  const handleTextChange = (event, type) => {
    const {
      target: { value },
    } = event;
    switch (type) {
      case "projectName":
        setProjectName(value);
        break;

      case "adTitle":
        setAdTitle(value);
        break;

      case "adDescription":
        setAdDescription(value);
        break;
      case "address":
        setAddress(value);
        break;
        case "name":
          setName(value);
          break;
          case "email":
            setEmail(value);
            break;
           
    }
  };

  const handleSelectFacing = (value) => {
    setFacing(value);
  };

  const handleMMPVerified = (event) => {
    setCheckVerifiedByMMP(event.target.checked)
  }

  const clearState = () => {
    navigate(location.pathname, { state: {} });
  };


  const handleSubmitForm = async(bodyData) => {
   if(isEdit){
    try{
      setIsLoading(true)
      const response = await updateCategoryPostApi({isDraft:false,isRejected:false, postedBy:data?.postedBy?.role === 0 ? data?.postedBy?._id : JSON.parse(localStorage?.getItem('userDetails'))?.user?._id,...bodyData, id: data?._id});
      if(response){
        form.resetFields()
        let signres = response?.err;
        setIsLoading(false)
        openNotificationWithIcon(signres ? 'error':'success',signres ? 'Please Check the Inputs':'Success',"Site Updated Successfully")
        // navigate("/site/user-sites")
        setCategory("House & Apartments")
        clearState()
      }else{
        setIsLoading(false)
        console.log('error in response')
        openNotificationWithIcon('error','Error','Please try again after sometime')
      }

    }
    catch (error) {
      setIsLoading(false)
      console.error("Error uploading images:", error);
      openNotificationWithIcon('error','Error','Please try again after sometime')
    }
   }
   else{
    try{
      setIsLoading(true)
      const response = await createCategoryPostApi({isDraft:false,isRejected:false, postedBy:JSON.parse(localStorage?.getItem('userDetails'))?.user?._id,...bodyData});
      if(response){
        form.resetFields()
        let signres = response?.err;
        setIsLoading(false)
        openNotificationWithIcon(signres ? 'error':'success',signres ? 'Please Check the Inputs':'Success',"Site Created Successfully")
        // navigate("/site/admin-sites")
        setCategory("House & Apartments")
        clearState()
      }else{
        setIsLoading(false)
        console.log('error in response')
        openNotificationWithIcon('error','Error','Please try again after sometime')
      }

    }
    catch (error) {
      setIsLoading(false)
      console.error("Error uploading images:", error);
      openNotificationWithIcon('error','Error','Please try again after sometime')
    }
  }
  };

  return (
    <ConfigProvider>
      <Layout>
        <Content>
        {contextHolder}
          

          <Form
            {...formItemLayout}
            variant={"filled"}
            onFinish={handleSubmitForm}
            form={form}
            initialValues={{
              lookingTo:"Sell", 
              subCategoryType:category.toLowerCase() === "House & Apartments".toLowerCase() ? "Flats/Apartments" :"Guest House",
              noofBedrooms:"1",
              noofBathrooms:"1",
              furnishing:"Furnished",
              projectStatus:"New Launch",
              listedBy:"Builder",
              bachelorsAllowed:false,
              carParking:"1",
              facing:"East",
              imageUrl: isEdit ? data?.imageUrl : [],
              roomSharing:"0",
              mealsIncluded:false

            }}
          >
        <Row gutter={16} style={{ width: "100%" }}>
      <Col span={12}>
        <Form.Item 
          label={
            <p style={{ fontSize: "14px", fontWeight: "600", fontFamily: "sans-serif", marginRight: 4 }}>
              Looking to:
            </p>
          }
          name={"lookingTo"}
          initialValue={saleType}
        >
          <Radio.Group onChange={handleSaleType} value={saleType}  style={{ width: "100%" }}>
            <Radio style={{ flex: 1 }} value={data?.postedBy?.role === 0 && data?.lookingTo === "sell" ? "sell":"Sell"}>Sell</Radio>
            <Radio style={{ flex: 1 }} value={data?.postedBy?.role === 0 ? "Rent/Lease" :"Rent"}>Rent</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          label={
            <p style={{ fontSize: "14px", fontWeight: "600", fontFamily: "sans-serif", marginRight: 4 }}>
              Category:
            </p>
          }
          name={"categoryType"}
          initialValue={category}
        >
          <Select
            defaultValue="House & Apartments"
            options={saleCategoryData}
            disabled={isEdit}
            onChange={handleSelectCategory}
            value={category}
            size="large"
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Col>
    </Row>
          <Divider />
            {category.toLowerCase() === "House & Apartments".toLowerCase() && (
              <HouseAndApartmentsForm
                houseType={houseType}
                bhk={bhk}
                bathroom={bathroom}
                furnished={furnished}
                saleType={saleType}
                projectStatus={projectStatus}
                listedBy={listedBy}
                buildUpArea={buildUpArea}
                carpetArea={carpetArea}
                isBachlorsAllowed={isBachlorsAllowed}
                monthlyMaintenance={monthlyMaintenance}
                totalFloors={totalFloors}
                floorNumber={floorNumber}
                carParking={carParking}
                facing={facing}
                handleSelectedButtonType={handleSelectedButtonType}
                handleValuesChange={handleValuesChange}
                handleSelectFacing={handleSelectFacing}
              />
            )}
            {category.toLowerCase() === "Lands & Plots".toLowerCase() && (
              <LandAndPlotsForm
                listedByData={listedByData}
                listedBy={listedBy}
                handleSelectedButtonType={handleSelectedButtonType}
                handleValuesChange={handleValuesChange}
                plotArea={plotArea}
                plotLength={plotLength}
                plotBreadth={plotBreadth}
                facingData={facingData}
                facing={facing}
                handleSelectFacing={handleSelectFacing}
              />
            )}
            {category.toLowerCase() === "Shops & Offices".toLowerCase() && (
              <ShopsAndOfficesForm
                furnished={furnished}
                handleSelectedButtonType={handleSelectedButtonType}
                saleType={saleType}
                projectStatus={projectStatus}
                listedBy={listedBy}
                handleValuesChange={handleValuesChange}
                buildUpArea={buildUpArea}
                carpetArea={carpetArea}
                monthlyMaintenance={monthlyMaintenance}
                carParking={carParking}
                washrooms={washrooms}
              />
            )}
            {category.toLowerCase() === "PG & Guest Houses".toLowerCase() && <PGAndGuestHousesForm 
            pgSubType={pgSubType}
            handleSelectedButtonType={handleSelectedButtonType}
            furnished={furnished}
            listedBy={listedBy}
            carParking={carParking}
            isMealsIncluded={isMealsIncluded}
            roomSharing={roomSharing}
            />}

            {/* Project Name */}
            {category.toLowerCase() !== "PG & Guest Houses".toLowerCase() && (
              <CustomFormTextField
                label={"Project Name"}
                name={"projectName"}
                required={false}
                maxLength={70}
                errorMessage={""}
                showCount={true}
                value={projectName}
                placeholder={"Please enter the project name."}
                onChange={(event) => handleTextChange(event, "projectName")}
              />
            )}

            {/* Ad Title */}
            <CustomFormTextField
              label={"Ad Title"}
              name={"title"}
              required={true}
              maxLength={70}
              errorMessage={"Please enter the Ad Title."}
              showCount={true}
              value={adTitle}
              placeholder={"Please enter the Ad Title."}
              onChange={(event) => handleTextChange(event, "adTitle")}
            />

            {/* Description */}
            <CustomFormDescriptionField
              errorMessage={"Please add the description."}
              name={"description"}
              label={"Description"}
              maxLength={4096}
              onChange={(event) => handleTextChange(event, "adDescription")}
              placeholder={"Please enter the description."}
              required={true}
              showCount={true}
              size={"large"}
              value={adDescription}
            />

            <Divider />

            {/* Price */}
            <Form.Item
              label="Set A Price"
              name="setPrice"
              rules={[{ required: true, message: "Please Enter Price" }]}
            >
              <InputNumber
                style={{ width: "100%" }}
                type="number"
                size={"large"}
                variant="outlined"
                placeholder="Price"
                addonBefore={<DollarOutlined />}
                min={0}
                controls={false}
                onChange={(value) => handleValuesChange(value, "price")}
                value={price}
              />
            </Form.Item>

            <Divider />

            {/* Photos */}
            <Form.Item
              label="Upload up to 20 photos"
              name="imageUrl" //need to change when we upload video currently only work for images
              getValueFromEvent={(e) => e}
              rules={[
                { required: true, message: "Please upload at least one file" },
              ]}
            >
              <FileUploader
                onFileUpload={handleFileUpload}
                uploadedFiles={uploadedFiles}
                isEdit={isEdit}
              />
            </Form.Item>

            <Divider />

    <PersoalDetailsForm
    name={name}
    email={email}
    address={address}
    handleTextChange={handleTextChange}
    state={state}
    phoneNumber={phoneNumber}
    handleValuesChange={handleValuesChange}
    handlePhoneNumberChange={handlePhoneNumberChange}
    handleMMPVerified={handleMMPVerified}
    checkVerifiedByMMP={checkVerifiedByMMP}
    />
            <Divider />

            <Form.Item wrapperCol={{ span: 16, offset: 10 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
          <Spin spinning={isLoading} fullscreen size="large" tip={"Please Wait"} />
        </Content>
      </Layout>
    </ConfigProvider>
  );
};

export default AddNewProperty;
