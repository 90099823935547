import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Modal, Card, Button, message } from 'antd';
import { PlusOutlined, CloseOutlined, EyeOutlined } from '@ant-design/icons';
import AWS from 'aws-sdk';

const FileUploader = ({ onFileUpload, value=[], onChange, isEdit,uploadedFiles }) => {
  const [files, setFiles] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewContent, setPreviewContent] = useState(null);
  const [filesInitialized, setFilesInitialized] = useState(false);
  const S3_BUCKET = "mmp-upload";
  const REGION = "ap-south-1";

  const s3 = new AWS.S3({
    accessKeyId: "AKIAXTORPZNUWMQYYBGN",
    secretAccessKey: "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",
    region: REGION,
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.gif', '.bmp', '.webp'],
    },
    onDrop: async (acceptedFiles) => {
      if (files.length + acceptedFiles.length <= 20) {
        const newFiles = await Promise.all(
          acceptedFiles.map(async (file) => {
            const previewUrl = URL.createObjectURL(file);
            const uploadedUrl = await uploadFileToS3(file);
            return { file, preview: previewUrl, url: uploadedUrl };
          })
        );

        const updatedFiles = [...files, ...newFiles];
        setFiles(updatedFiles);
        onChange(updatedFiles.map((f) => f.url))
        onFileUpload(updatedFiles.map((f) => f.url)); 
      } else {
        alert("You can upload up to 20 files only.");
      }
    },
  });

  const uploadFileToS3 = async (file) => {
    const params = {
      Bucket: S3_BUCKET,
      Key: `uploads/${Date.now()}-${file.name}`,
      Body: file,
      ContentType: file.type,
      ACL: 'public-read',
    };

    try {
      const uploadResult = await s3.upload(params).promise();
      message.success('File uploaded successfully');
      return uploadResult.Location; // Return the S3 URL
    } catch (error) {
      message.error('Error uploading Image');
      return null;
    }
  };

  const handlePreview = (file) => {
    setPreviewContent(
      file.file.type.startsWith('image/') ? (
        <img
          src={file.preview}
          alt="preview"
          style={{ width: '100%', maxHeight: '90vh', objectFit: 'contain' }}
        />
      ) : (
        <video
          src={file.preview}
          controls
          style={{ width: '100%', maxHeight: '90vh', objectFit: 'contain' }}
        />
      )
    );
    setPreviewVisible(true);
  };

  const handleRemoveFile = (fileToRemove) => {
    const updatedFiles = files.filter((file) => file !== fileToRemove);
    setFiles(updatedFiles);
    onChange(updatedFiles.map((f) => f.url));
    onFileUpload(updatedFiles.map((f) => f.url));
  };

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  useEffect(() => {
    if (isEdit && uploadedFiles.length > 0 && !filesInitialized) {
      // Initialize files only once
      const prepopulatedFiles = uploadedFiles.map((url) => ({
        file: { name: 'uploaded_file', type: 'image/jpeg' },
        preview: url,
        url: url,
      }));

      setFiles(prepopulatedFiles);
      onChange(prepopulatedFiles.map((f) => f.url));
      onFileUpload(prepopulatedFiles.map((f) => f.url));
      setFilesInitialized(true); // Mark files as initialized
    }
  }, [isEdit, uploadedFiles, filesInitialized, onChange, onFileUpload]);

  return (
    <>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: 16 }}>
        {files.map((file) => (
          <Card
            key={file.file.name}
            hoverable
            style={{ width: 120, textAlign: 'center' }}
            cover={
              file.file.type.startsWith('image/') ? (
                <img src={file.preview} alt="preview" style={{ height: '100px', objectFit: 'cover' }} />
              ) : (
                <video src={file.preview} style={{ height: '100px', objectFit: 'cover' }} />
              )
            }
            actions={[
              <Button
                icon={<EyeOutlined />}
                size="small"
                type="text"
                onClick={() => handlePreview(file)}
              />,
              <Button
                icon={<CloseOutlined />}
                size="small"
                type="text"
                onClick={() => handleRemoveFile(file)}
              />,
            ]}
          />
        ))}
        {files.length < 20 && (
          <div
            {...getRootProps({ className: 'dropzone' })}
            style={{
              border: '2px dashed #d9d9d9',
              borderRadius: '8px',
              padding: '20px',
              textAlign: 'center',
              backgroundColor: '#fafafa',
              width: 120,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <input {...getInputProps()} style={{ display: "none" }} />
            <PlusOutlined style={{ fontSize: '24px', color: '#999', fontWeight: "bold" }} />
            <p style={{ margin: 0 }}>Upload</p>
          </div>
        )}
      </div>

      <Modal
        open={previewVisible}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
        width={700}
        centered
      >
        {previewContent}
      </Modal>
    </>
  );
};

export default FileUploader;