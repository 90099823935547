import React, {useState} from 'react'
import { ConfigProvider, Layout, Grid, Form,Radio,Button, Select, Input, notification, Spin, Checkbox } from 'antd'
import {statesListData, typeOfHouseData } from "../Dummy Data/dummyData";
import FileUploader from './Components/CustomImage';
import { postPropertiesApi } from '../Auth/properties';

const { Content } = Layout;
const { useBreakpoint } = Grid;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const NewProperty = () => {
    const [propertyFor, setPropertyFor] = useState("Sell");
    const [propertyType, setPropertyType] = useState("Flats/Apartments");
    const [bhk, setBHK] = useState("1");
    const [bathrooms, setBathRooms] = useState("1");
    const [balconies, setBalconies] = useState("1");
    const [state, setState] = useState(null)
    const [propertyName, setPropertyName] = useState(null);
    const [propertyAddress, setPropertyAddress] = useState(null);
    const [saleType, setSaleType] = useState(null);
    const [ownership, setOwnership] = useState(null);
    const [numberOfFloors, setNumberOfFloors] = useState(null);
    const [availability, setAvailability] = useState(null);
    const [propertyOnTheFloor, setPropertyOnTheFloor] = useState(null);
    const [builduparea, setBuildUpArea] = useState(null);
    const [carpetArea, setCarpetArea] = useState(null);
    const [plotLandArea, setPlotLandArea] = useState(null);
    const [buildupareaUnit, setBuildUpAreaUnit] = useState(null);
    const [carpetAreaUnit, setCarpetAreaUnit] = useState(null);
    const [plotLandAreaUnit, setPlotLandAreaUnit] = useState(null);
    const [expectedPrice, setExpectedPrice] = useState(null);
    const [bookingAmount, setBookingAmount] = useState(null);
    const [maintenanceCharges, setMaintenanceCharges] = useState(null);
    const [name, setName] = useState(null);
    const [mobileNumber, setMobileNumber] = useState(null);
    const [email, setEmail] = useState(null);
    const [Iam, setIam] = useState("owner");
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [description, setDescription] =useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [checkVerifiedByMMP, setCheckVerifiedByMMP] = useState(false);

    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type,head,content) => {
      api[type]({
        message: head,
        description:content,
      });
    };
 
    const [form] = Form.useForm()



    const handlePropertyFor = (e) => {
      setPropertyFor(e.target.value);
    }

    const handlePropertyType = (e) => {
      setPropertyType(e);
    }

    const handleNoOfBHK = (e) => {
      setBHK(e);
    }

    const handleNoOfBathrooms = (e) => {
      setBathRooms(e);
    }

    const handleNoOfBalconies = (e) => {
      setBalconies(e);
    }

    const handleState = (e) => {
      setState(e);
    }

    const handlePropertyName = (event) =>{
      setPropertyName(event.target.value);
    }

    const handlePropertyAddress = (event) =>{
      setPropertyAddress(event.target.value);
    }

    const handleSaleType = (event) =>{
      setSaleType(event.target.value);
    }

    const handleOwnership = (event) =>{
      setOwnership(event.target.value);
    }

    const handleNumberOfFloors = (event) =>{
      setNumberOfFloors(event.target.value);
    }

    const handkleAvailabilty = (event) =>{
      setAvailability(event.target.value);
    }

    const handlePropertyOnTheFloor = (event) =>{
      setPropertyOnTheFloor(event.target.value);
    }

    const handleBuildUpArea = (event) =>{
      setBuildUpArea(event.target.value);
    }

    const handleCarpetArea = (event) =>{
      setCarpetArea(event.target.value);
    }

    const handlePlotLandArea = (event) =>{
      setPlotLandArea(event.target.value);
    }

    const handleBuildUpAreaUnit = (event) =>{
      setBuildUpAreaUnit(event.target.value);
    }

    const handleCarpetAreaUnit = (event) =>{
      setCarpetAreaUnit(event.target.value);
    }

    const handlePlotLandAreaUnit = (event) =>{
      setPlotLandAreaUnit(event.target.value);
    }

    const handleExpectedPrice = (event) =>{
      setExpectedPrice(event.target.value);
    }
    const handleBookingAmount = (event) =>{
      setBookingAmount(event.target.value);
    }
    const handleMaintenanceCharges = (event) =>{
      setMaintenanceCharges(event.target.value);
    }

    const handleName = (event) =>{
      setName(event.target.value);
    }

    const handleMobileNumber = (event) =>{
      setMobileNumber(event.target.value);
    }

    const handleEmail = (event) =>{
      setEmail(event.target.value);
    }

    const handleIam = (e) => {
      setIam(e.target.value);
    }

    const handleFileUpload = (files) => {
      const imagesList = [...files, ...uploadedFiles];
      setUploadedFiles(imagesList);
    };

    const handleDescription = (event) => {
      setDescription(event.target.value);
    }

    const handleVerfitedMMP = (event) => {
      setCheckVerifiedByMMP(event.target.checked);
    }

    
  

    

    const handleSubmitForm = async (data) => {
        try{
      setIsLoading(true)
      const response = await postPropertiesApi({isDraft:false, postedBy:JSON.parse(localStorage?.getItem('userDetails'))?.user?._id,...data});
      if(response){
        // alert(JSON.stringify(response))
        console.log(JSON.stringify(response))
        form.resetFields()
        let signres = response?.err;
        setIsLoading(false)
        openNotificationWithIcon(signres ? 'error':'success',signres ? 'Error':'Success',response?.msg)
      }else{
        setIsLoading(false)
        console.log('error in response')
        openNotificationWithIcon('error','Error','Please try again after sometime')
      }

    }
    catch (error) {
      setIsLoading(false)
      console.error("Error uploading images:", error);
      openNotificationWithIcon('error','Error','Please try again after sometime')
    }
    }

  return (
    <ConfigProvider>
    <Layout>
      <Content>
      {contextHolder}
        

        <Form
          {...formItemLayout}
          form={form}
          variant={"filled"}
          onFinish={handleSubmitForm}
         initialValues={{propertyFor:"Sell", iAm:"owner"}}
        >

{/* Property For */}
        <Form.Item
          label={<p style={{ fontSize: "14px", fontWeight: "600", fontFamily: "sans-serif", marginRight: 4 }}>Property For </p>}
          name={"propertyFor"}
         
        >
          <Radio.Group
          onChange={handlePropertyFor}
          value={propertyFor}
           style={{ }}>
            <Radio style={{ flex: 1, border:"1px solid rgb(244 241 240)",  height:30, borderRadius:"16px", alignItems:"center", justifyContent:"center",padding:4 }} value="Sell">Sell</Radio>
            <Radio style={{ flex: 1, border:"1px solid rgb(244 241 240)",  height:30, borderRadius:"16px", alignItems:"center", justifyContent:"center", padding:4 }} value="Rent/Lease">Rent/Lease</Radio>
            <Radio style={{ flex: 1, border:"1px solid rgb(244 241 240)",  height:30, borderRadius:"16px", alignItems:"center", justifyContent:"center", padding:4 }} value="PG">PG</Radio>
          </Radio.Group>
        </Form.Item>

{/* Property Type */}
        <Form.Item label={<p style={{ fontSize: "14px", fontWeight: "600", fontFamily: "sans-serif", marginRight: 4 }}>{"Property Type"}</p>} name={"propertyType"} initialValue={typeOfHouseData[0]}>
            <Radio.Group
            value={propertyType}
            defaultValue={typeOfHouseData[0]}
            onChange={handlePropertyType}
            size={'large'}
            buttonStyle="outline"
            style={{ display: "flex",}}
            >
            {typeOfHouseData.map((item, index)=><Radio.Button style={{height:"auto",}} value={item} key={item}>{item}</Radio.Button>)}  
            </Radio.Group>
        </Form.Item> 

{/* No. of Bedrooms */}
        <Form.Item label={<p style={{ fontSize: "14px", fontWeight: "600", fontFamily: "sans-serif", marginRight: 4 }}>{"No. of Bedrooms"}</p>} name={"noofBedrooms"} initialValue={'1'}>
            <Radio.Group
            value={bhk}
            defaultValue={'1'}
            onChange={handleNoOfBHK}
            size={'large'}
         
            style={{ display: "flex",}}
            >
            {["1", "2", "3", "4", "4+"].map((item)=><Radio.Button style={{height:"auto",}} value={item} key={item}>{item} BHK</Radio.Button>)}  
            </Radio.Group>
        </Form.Item> 

{/* No. of Bathrooms */}
        <Form.Item label={<p style={{ fontSize: "14px", fontWeight: "600", fontFamily: "sans-serif", marginRight: 4 }}>{"No. of Bathrooms"}</p>} name={"noofBathrooms"} initialValue={'1'}>
            <Radio.Group
            value={bathrooms}
            defaultValue={'1'}
            onChange={handleNoOfBathrooms}
            size={'large'}
           
            style={{ display: "flex",}}
            >
            {["1", "2", "3", "4"].map((item)=><Radio.Button style={{height:"auto",}} value={item} key={item}>{item}</Radio.Button>)}  
            </Radio.Group>
        </Form.Item> 

{/* No. of Balconies */}
         <Form.Item label={<p style={{ fontSize: "14px", fontWeight: "600", fontFamily: "sans-serif", marginRight: 4 }}>{"No. of Balconies"}</p>} name={"noofBalconies"} initialValue={'1'}>
            <Radio.Group
            value={balconies}
            defaultValue={'1'}
            onChange={handleNoOfBalconies}
            size={'large'}
           
            style={{ display: "flex",}}
            >
            {["1", "2", "3", "4"].map((item)=><Radio.Button style={{height:"auto",}} value={item} key={item}>{item}</Radio.Button>)}  
            </Radio.Group>
        </Form.Item>

{/* State */}
        <Form.Item
                label={<p style={{ fontSize: "14px", fontWeight: "600", fontFamily: "sans-serif", marginRight: 4 }}>{"State"}</p>} 
                name={"state"}
                rules={[{ required: true, message: "Please select your state" }]}
               
            >
                <Select
                        placeholder="Enter your state"
                        style={{ width: "100%", }}
                        onChange={handleState}
               
                        options={statesListData}
                        value={state}
                        />
        </Form.Item>

{/* ProPerty or Society Name */}
        <Form.Item
      label={<p style={{ fontSize: "14px", fontWeight: "600", fontFamily: "sans-serif", marginRight: 4 }}>{"Project or Society Name"}</p>}
      name={"projectSocietyName"}
      rules={[{ required: true, message: "Please enter your property name" }]}
    >
      <Input
        showCount={false}
        size={"large"}
        style={{ width: "100%" }}   
        placeholder={"Enter property name"}
        maxLength={100}
        onChange={handlePropertyName}
        value={propertyName}
      />
      
    </Form.Item>

{/* ProPerty or Society Address */}
    <Form.Item
      label={<p style={{ fontSize: "14px", fontWeight: "600", fontFamily: "sans-serif", marginRight: 4 }}>{"Property Address"}</p>}
      name={"address"}
      rules={[{ required: true, message: "Please enter your property address" },]}
    >
      <Input.TextArea
        showCount={false}
        size={"large"}
        style={{ width: "100%", }}   
        placeholder={"Enter property address"}
        maxLength={4000}
        onChange={handlePropertyAddress}
        value={propertyAddress}
        autoSize
       
      />
      
    </Form.Item>

{/* Sale type */}
    <Form.Item
      label={<p style={{ fontSize: "14px", fontWeight: "600", fontFamily: "sans-serif", marginRight: 4 }}>{"Sale Type"}</p>}
      name={"saleType"}
      rules={[{ required: true, message: "Please enter sale type" }]}
    >
      <Input
        showCount={false}
        size={"large"}
        style={{ width: "100%" }}   
        placeholder={"Enter sale type"}
        maxLength={100}
        onChange={handleSaleType}
        value={saleType}
      />
      
    </Form.Item>

{/* Ownership */}
    <Form.Item
      label={<p style={{ fontSize: "14px", fontWeight: "600", fontFamily: "sans-serif", marginRight: 4 }}>{"Ownership"}</p>}
      name={"ownerShip"}
      rules={[{ required: true, message: "Please enter ownership" }]}
    >
      <Input
        showCount={false}
        size={"large"}
        style={{ width: "100%" }}   
        placeholder={"Enter ownership"}
        maxLength={100}
        onChange={handleOwnership}
        value={ownership}
      />
      
    </Form.Item>

{/* Number of floors */}
    <Form.Item
          label={<p style={{ fontSize: "14px", fontWeight: "600", fontFamily: "sans-serif", marginRight: 4 }}>{"Number of floors"}</p>}
          name={"numberofFloors"}
          rules={[{ required: true, message: "Please enter number of floors" }, {pattern: /^\d/, message: 'Please enter digits only' }]}
        >
        <Input
          showCount={false}
          size={"large"}
          style={{ width: "100%" }}   
          placeholder={"Enter number of floors"}
          maxLength={100}
          onChange={handleNumberOfFloors}
          value={numberOfFloors}
        />
      
      </Form.Item>

{/* Availability */}
      <Form.Item
                label={<p style={{ fontSize: "14px", fontWeight: "600", fontFamily: "sans-serif", marginRight: 4 }}>{"Availability"}</p>}
                name={"availability"}
                rules={[{ required: true, message: "Please enter availability" }]}
              >
              <Input
                showCount={false}
                size={"large"}
                style={{ width: "100%" }}   
                placeholder={"Enter availability"}
                maxLength={100}
                onChange={handkleAvailabilty}
                value={availability}
              />
            
            </Form.Item>

{/* proporty on the floor */}
      <Form.Item
                label={<p style={{ fontSize: "14px", fontWeight: "600", fontFamily: "sans-serif", marginRight: 4 }}>{"Property on the floor"}</p>}
                name={"propertyonFloor"}
                rules={[{ required: true, message: "Please enter property on the floor" }]}
              >
              <Input
                showCount={false}
                size={"large"}
                style={{ width: "100%" }}   
                placeholder={"Enter property on the floor"}
                maxLength={100}
                onChange={handlePropertyOnTheFloor}
                value={propertyOnTheFloor}
              />
            
            </Form.Item>

{/* Built up area */}
    <Form.Item  style={{ marginBottom: 0 }}
    label={<p style={{ fontSize: "14px", fontWeight: "600", fontFamily: "sans-serif", marginRight: 4 }}>{"Built up area"}</p>}
    >
      <Form.Item
        name={"builtupArea"}
        rules={[{ required: true, message: "Please enter built up area" },
          {pattern: /^\d/, message: 'Please enter built up area must be in numbers only' }
        ]}
        style={{ display: 'inline-block', width: 'calc(70% - 8px)' }}
      >
        <Input  
         showCount={false}
                size={"large"}
                style={{ width: "100%" }}   
                placeholder={"Enter built up area"}
                maxLength={100}
                onChange={handleBuildUpArea}
                value={builduparea} />
      </Form.Item>

      <Form.Item
        name="builtupVal"
        rules={[{ required: true, message: "Please enter build up area unit" }]}
        style={{ display: 'inline-block', width: 'calc(30% - 8px)', margin: '0 8px' }}
      >
        <Input  
         showCount={false}
                size={"large"}
                style={{ width: "100%" }}   
                placeholder={"Enter area unit"}
                maxLength={100}
                onChange={handleBuildUpAreaUnit}
                value={buildupareaUnit} />
      </Form.Item>
    </Form.Item>

{/* Carpet area */}
      <Form.Item  style={{ marginBottom: 0 }}
          label={<p style={{ fontSize: "14px", fontWeight: "600", fontFamily: "sans-serif", marginRight: 4 }}>{"Carpet area"}</p>}
          >
            <Form.Item
              name={"carpetArea"}
              rules={[{ required: true, message: "Please enter carpet area" },
                {pattern: /^\d/, message: 'Please enter carpet area must be in numbers only' }
              ]}
              style={{ display: 'inline-block', width: 'calc(70% - 8px)' }}
            >
              <Input  
              showCount={false}
                      size={"large"}
                      style={{ width: "100%" }}   
                      placeholder={"Enter carpet area"}
                      maxLength={100}
                      onChange={handleCarpetArea}
                      value={carpetArea} />
            </Form.Item>

            <Form.Item
              name="carpetAreaVal"
              rules={[{ required: true, message: "Please enter carpet area unit" }]}
              style={{ display: 'inline-block', width: 'calc(30% - 8px)', margin: '0 8px' }}
            >
              <Input  
              showCount={false}
                      size={"large"}
                      style={{ width: "100%" }}   
                      placeholder={"Enter area unit"}
                      maxLength={100}
                      onChange={handleCarpetAreaUnit}
                      value={carpetAreaUnit} />
            </Form.Item>
          </Form.Item>

{/* Plot Land area */}
      <Form.Item  style={{ marginBottom: 0 }}
          label={<p style={{ fontSize: "14px", fontWeight: "600", fontFamily: "sans-serif", marginRight: 4 }}>{"Plot / Land area"}</p>}
          >
            <Form.Item
              name={"plotLandArea"}
              rules={[{ required: true, message: "Please enter plot/land area" },
                {pattern: /^\d/, message: 'Please enter plot/land area must be in numbers only' }
              ]}
              style={{ display: 'inline-block', width: 'calc(70% - 8px)' }}
            >
              <Input  
              showCount={false}
                      size={"large"}
                      style={{ width: "100%" }}   
                      placeholder={"Enter plot/land area"}
                      maxLength={100}
                      onChange={handlePlotLandArea}
                      value={plotLandArea} />
            </Form.Item>

            <Form.Item
              name="plotLandAreaVal"
              rules={[{ required: true, message: "Please enter plot/land area unit" }]}
              style={{ display: 'inline-block', width: 'calc(30% - 8px)', margin: '0 8px' }}
            >
              <Input  
              showCount={false}
                      size={"large"}
                      style={{ width: "100%" }}   
                      placeholder={"Enter area unit"}
                      maxLength={100}
                      onChange={handlePlotLandAreaUnit}
                      value={plotLandAreaUnit} />
            </Form.Item>
          </Form.Item>

{/* Expected Price */}
      <Form.Item
            label={<p style={{ fontSize: "14px", fontWeight: "600", fontFamily: "sans-serif", marginRight: 4 }}>{"Expected Price"}</p>}
            name={"expectedPrice"}
            rules={[{ required: true, message: "Please enter expected price" }]}
          >
            <Input
              showCount={false}
              size={"large"}
              style={{ width: "100%" }}   
              placeholder={"Enter expected price"}
              maxLength={100}
              onChange={handleExpectedPrice}
              value={expectedPrice}
            />
            
          </Form.Item>

{/* Booking amount */}
      <Form.Item
            label={<p style={{ fontSize: "14px", fontWeight: "600", fontFamily: "sans-serif", marginRight: 4 }}>{"Booking amount"}</p>}
            name={"bookingAmount"}
            rules={[{ required: true, message: "Please enter booking amount" }]}
          >
            <Input
              showCount={false}
              size={"large"}
              style={{ width: "100%" }}   
              placeholder={"Enter booking amount"}
              maxLength={100}
              onChange={handleBookingAmount}
              value={bookingAmount}
            />
            
          </Form.Item>

{/* Maintenance charges */}
      <Form.Item
            label={<p style={{ fontSize: "14px", fontWeight: "600", fontFamily: "sans-serif", marginRight: 4 }}>{"Maintenance charges"}</p>}
            name={"maintenanceCharges"}
            rules={[{ required: true, message: "Please enter maintenance charges" }]}
          >
            <Input
              showCount={false}
              size={"large"}
              style={{ width: "100%" }}   
              placeholder={"Enter maintenance charges"}
              maxLength={100}
              onChange={handleMaintenanceCharges}
              value={maintenanceCharges}
            />
            
          </Form.Item>

{/* Name */}
      <Form.Item
                      label={<p style={{ fontSize: "14px", fontWeight: "600", fontFamily: "sans-serif", marginRight: 4 }}>{"Name"}</p>}
                      name={"name"}
                      rules={[{ required: true, message: "Please enter name" }]}
                    >
                    <Input
                      showCount={false}
                      size={"large"}
                      style={{ width: "100%" }}   
                      placeholder={"Enter name"}
                      maxLength={100}
                      onChange={handleName}
                      value={name}
                    />
                  
                  </Form.Item>

{/* Mobile Number */}
      <Form.Item
                    label="Mobile Number"
                    name="mobileNumber"
                    rules={[
                      { required: true, message: 'Please input your phone number!' },
                      {pattern: /^\d{10}$/, message: 'Phone number must be in digits long' }
                    ]}
                  >
            
                <Input showCount prefix="+91" value={mobileNumber} maxLength={10} 
                onChange={handleMobileNumber}
                
                />
      
            </Form.Item>

{/* Email */}
      <Form.Item
                label="Email ID"
                name="emailId"
                rules={[
                  { required: true, message: 'Please enter your email ID' },
                  { 
                    type: 'email', 
                    message: 'The input is not valid E-mail!' 
                  }
                ]}
              >
                <Input placeholder="Enter your Email" style={{ width:'100%' }} maxLength={50}
                value={email}
                onChange={handleEmail}
                />
              </Form.Item>

{/* I am */}
<Form.Item
          label={<p style={{ fontSize: "14px", fontWeight: "600", fontFamily: "sans-serif", marginRight: 4 }}>I AM</p>}
          name={"iAm"}
      
        >
          <Radio.Group
          onChange={handleIam}
          value={Iam}
           style={{ }}>
            <Radio style={{ flex: 1, border:"1px solid rgb(244 241 240)",  height:30, borderRadius:"16px", alignItems:"center", justifyContent:"center",padding:4 }} value="owner">Owner</Radio>
            <Radio style={{ flex: 1, border:"1px solid rgb(244 241 240)",  height:30, borderRadius:"16px", alignItems:"center", justifyContent:"center", padding:4 }} value="agent">Agent</Radio>
           
          </Radio.Group>
        </Form.Item>

 {/* Images */}
 <Form.Item
              label={<p style={{ fontSize: "14px", fontWeight: "600", fontFamily: "sans-serif", marginRight: 4 }}>{"Upload Images"}</p>}
              name="imgurl" //need to change when we upload video currently only work for images
              getValueFromEvent={(e) => e}
              rules={[
                { required: true, message: "Please upload at least one file" },
              ]}
            >
              <FileUploader
                onFileUpload={handleFileUpload}
                uploadedFiles={uploadedFiles}
              />
            </Form.Item>

{/* Description */}
<Form.Item
      label={<p style={{ fontSize: "14px", fontWeight: "600", fontFamily: "sans-serif", marginRight: 4 }}>{"Description"}</p>}
      name={"propertyDescriptions"}
      rules={[{ required: true, message: "Please write your description" }]}
    >
      <Input.TextArea
        showCount={true}
        size={"large"}
        style={{ width: "100%", }}   
        placeholder={"Write description"}
        maxLength={4096}
        onChange={handleDescription}
        value={description}
        autoSize
       
      />
      
    </Form.Item>

    {/* Verfied By MMP */}
    <Form.Item name={"VerifiedbyMMP"} required label="Verified by MMP" valuePropName="checked"
            initialValue={checkVerifiedByMMP}
            >
              <Checkbox
                  checked={checkVerifiedByMMP}
                  onChange={handleVerfitedMMP}
                  defaultChecked={checkVerifiedByMMP}
                  
                />
            </Form.Item>

            
        <Form.Item wrapperCol={{ span: 16, offset: 10 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
        </Form>
        <Spin spinning={isLoading} fullscreen size="large" tip={"Please Wait"} />
        </Content>
        </Layout>
        </ConfigProvider>
  )
}

export default NewProperty