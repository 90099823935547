import React from 'react';
import {
  Button,
  Form,
  Input,
} from 'antd';
import UploadImg from '../assets/uploadimg.png'

const options = [];
for (let i = 10; i < 36; i++) {
  options.push({
    value: i.toString(36) + i,
    label: i.toString(36) + i,
  });
}

const Settings = () => {

  return (
    <>

      <Form
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="vertical"
        // disabled={componentDisabled}
        style={{
          maxWidth: 600,
        }}
      >

        <div style={{padding:5,display:'flex'}}>
          <div style={{padding:5}}>
            <img src={UploadImg} alt='img' />
          </div>
          <div style={{padding:5}}>
            <div style={{display:'flex'}}>
                <div style={{width:200,margin:"0px 20px"}}>
                  <div>First Name</div>
                  <Form.Item>
                    <Input style={{width:190}} />
                  </Form.Item>
                </div>
                <div style={{width:200,margin:"0px 20px"}}>
                  <div>Last Name</div>
                  <Form.Item>
                    <Input style={{width:190}} />
                  </Form.Item>
                </div>
            </div>

            <div style={{display:'flex'}}>
                <div style={{width:200,margin:"0px 20px"}}>
                  <div>Phone Number</div>
                  <Form.Item>
                    <Input style={{width:190}} />
                  </Form.Item>
                </div>
                <div style={{width:200,margin:"0px 20px"}}>
                  <div>Email Id</div>
                  <Form.Item>
                    <Input style={{width:190}} />
                  </Form.Item>
                </div>
            </div>
          </div>
        </div>

        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
            {/* <Checkbox
              // checked={componentDisabled}
              // onChange={(e) => setComponentDisabled(e.target.checked)}
            >
              Verified by MMP
            </Checkbox> */}
              <Form.Item>
              <Button type="primary">Save</Button>
            </Form.Item>
        </div>



      </Form>
    </>
  );
};
export default Settings;