import React, {useState} from "react";
import { SettingOutlined } from "@ant-design/icons";
import { Avatar, Button, notification } from "antd";
import { rejectProperty, verifyProperty } from "../Auth/properties";

export default function DetailSite({ property, setIsList }) {

  const [loading, setLoading] =useState(false);
  // const [checkButton,setCheckButton] = useState(!property.isRejected)
  const [checkApproved,setCheckApproved] = useState(property.isDraft)
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, head, content) => {
    api[type]({
      message: head,
      description: content,
    });
  };

  return (
    <div style={{ background: "#fff", borderRadius: 5 }}>
      {contextHolder}
      <div
        style={{
          padding: 10,
          borderRadius: 10,
          width: "100%",
        }}
      >
        <div style={{ display: "flex",flexWrap:'wrap' }}>
          <img
            style={{ borderRadius: 5, width: 300, height: 200 }}
            alt="example"
            src={property?.imageUrl[0].split('?')[0]}
          />
          <div style={{ marginLeft: 6 }}>
            <div
              style={{ display: "flex", alignItems: "center", margin: "5px 0" }}
            >
              <div style={{ fontWeight: "bold" }}>{property?.propertyType}</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    margin: 5,
                    height: 7,
                    width: 7,
                    borderRadius: 50,
                    backgroundColor: "red",
                  }}
                ></div>
                <div style={{ fontSize: 14 }}>For {property?.propertyFor}</div>
              </div>
            </div>

            <div style={{ display: "flex", fontSize: 12 }}>
              {[property?.iAm, property?.propertyType, property?.saleType, property?.availability
              ].map((ele) => (
                <div
                  style={{
                    marginRight: 2,
                    padding: "2px 5px",
                    background: "red",
                    borderRadius: 8,
                  }}
                >
                  {ele}
                </div>
              ))}
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: 6 }}>
                <SettingOutlined />
              </div>
              <div style={{ margin: "5px 0", color: "#787878" }}>
                {property?.address}, {property?.state}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "5px 0",
              }}
            >
              <div>₹{property?.expectedPrice}</div>
            </div>
          </div>
        </div>

        <div>
          <div>
            <div style={{ fontWeight: "bold", marginTop: 5 }}>Posted By</div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "5px 0" }}
          >
            <div style={{ marginRight: 6 }}>
              <Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=8" />
            </div>
            <div>
              <div style={{ fontWeight: "bold" }}>
                {property?.postedBy?.username}
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: 6 }}>
                  <SettingOutlined />
                </div>
                <div style={{ margin: "5px 0", color: "#787878" }}>
                  {/* Divine Shanthi nagar, sulur, Coimbatore */}
                  {property?.postedBy?.email}
                </div>
              </div>
            </div>
          </div>

          <div>
            <div style={{ fontWeight: "bold", margin: "5px 0" }}>
              Description
            </div>
          </div>
          <div style={{ color: "#787878" }}>{property?.description}</div>

          <div>
            <div style={{ fontWeight: "bold", marginTop: 10 }}>Gallery</div>
          </div>
          <div className="sitegallary">
            {property?.imageUrl.map((ele) => {
              return (
                <div style={{ margin: 5 }}>
                  {/* <h5>{ele?.title}</h5> */}
                  <div>
                    <img
                      style={{ borderRadius: 5, width: 200, height: 200 }}
                      alt="example"
                      src={ele?.split('?')[0]}
                    />
                  </div>
                </div>
              );
            })}
          </div>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              style={{
                padding: "10px 16px",
                border: "1px solid #0961F5",
                borderRadius: 4,
                color: "#0961F5",
                marginRight: 10,
                cursor: "pointer",
              }}
              onClick={() => setIsList(true)}
            >
              Back to List
            </Button>
{
  !checkApproved && !property?.verifiedByMMP &&
  <Button
  loading={loading}
    style={{
      padding: "10px 16px",
      borderRadius: 4,
      color: "#fff",
      marginRight: 10,
      background: "blue",
      cursor: "pointer",
    }}
    onClick={async () => {
      try {
        let response = await verifyProperty({id:property?._id,status:false});
        if (response) {
        let signres = response?.err;
          openNotificationWithIcon(
            signres ? "error" : "success",
            signres ? "Error" : "Success",
            response?.msg
          );
          if(!signres){
            setCheckApproved(true)
          }
        }else{
        openNotificationWithIcon('error','Error','Please try again after sometime')
        }
        setLoading(loading)
      } catch (error) {
        console.log(error)
        openNotificationWithIcon('error','Error','Please try again after sometime')
        setLoading(loading)
      }
    }}
  >
    MMP Verified
  </Button>}{ checkApproved &&
              <Button
              loading={loading}
                style={{
                  padding: "10px 16px",
                  background: "#82E85E",
                  borderRadius: 4,
                  color: "#fff",
                  cursor: "pointer",
                  marginRight:10
                }}
                onClick={async () => {
                  try {
                    // isReject false to approve
                    let response = await rejectProperty({id:property?._id,status:false});
                    if (response) {
                    let signres = response?.err;
                      openNotificationWithIcon(
                        signres ? "error" : "success",
                        signres ? "Error" : "Success",
                        response?.msg
                      );
                      if(!signres){
                        setCheckApproved(false)
                      }
                    }else{
                    openNotificationWithIcon('error','Error','Please try again after sometime')
                    }
                    setLoading(loading)
                  } catch (error) {
                    console.log(error)
                    openNotificationWithIcon('error','Error','Please try again after sometime')
                    setLoading(loading)
                  }
  
                }}
              >
                Approve
              </Button>
}

            <Button
            style={{
              padding: "10px 16px",
              borderRadius: 4,
              color: "#fff",
              marginRight: 10,
              background: "#FF5B5B",
              cursor: "pointer",
            }}
            onClick={async () => {
              try {
                // isReject true to reject
                let response = await rejectProperty({id:property?._id,status:true});
                if (response) {
                let signres = response?.err;
                  openNotificationWithIcon(
                    signres ? "error" : "success",
                    signres ? "Error" : "Success",
                    response?.msg
                  );
                  // if(!signres){
                  //   setCheckButton(false)
                  // }
                }else{
                openNotificationWithIcon('error','Error','Please try again after sometime')
                }
                setLoading(loading)
              } catch (error) {
                console.log(error)
                openNotificationWithIcon('error','Error','Please try again after sometime')
                setLoading(loading)
              }
            }}
          >
            Reject
          </Button>

          </div>
        </div>
      </div>
    </div>
  );
}
