import React from 'react';
import { ConfigProvider, Layout, Space, Table, Button} from 'antd';
import {CloseOutlined, CheckOutlined} from "@ant-design/icons"
const {Content} = Layout




const DeleteAccount = () => {

    const columns = [
        {
            title: 'Id',
            dataIndex: 'key',
            key: 'key',
          },
        {
          title: 'User',
          dataIndex: 'user',
          key: 'user',
        },
        {
          title: 'Subscription',
          dataIndex: 'subscription',
          key: 'subscription',
        },
        {
          title: 'Details',
          dataIndex: 'details',
          key: 'details',
        },
        
        {
          title: 'Action',
          key: 'action',
          render: (_, record) => (
            <Space size="middle">
          <Button icon={<CloseOutlined />} shape="round" type='link' size={24} style={{color:"blue", fontWeight:"bold"}}>
            Reject
          </Button>
          <Button icon={<CheckOutlined />} shape="round"  type='link'  size={24} style={{color: "#f00",  fontWeight:"bold"}}>
            Accept
          </Button>
            </Space>
          ),
        },
      ];
      const data = [
        {
          key: '1',
          user: 'John',
          subscription: "Annual",
          details: 'Lorem ipsum dolor sit amet',
         
        },
        {
          key: '2',
          user: 'Smith',
          subscription: "6 Months",
          details: 'Lorem ipsum dolor sit amet',
         
        },
        {
            key: '3',
            user: 'Smit',
            subscription: "Free",
            details: 'Lorem ipsum dolor sit amet Lorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit amet',
           
        },
        {
            key: '4',
            user: 'John',
            subscription: "Premium",
            details: 'Lorem ipsum dolor sit amet',
           
          },
         
     
        

      ];

  return (
    <ConfigProvider 
    theme={{
        components: {
         Table:{
      
            headerBg:"#e7e7e7",
          
           
         }
        },
      }}
    >
      <Layout>
        <Content style={{ padding: "20px" }}>
        <Table columns={columns} dataSource={data} pagination={false} bordered/>
        </Content>
        </Layout>
        </ConfigProvider>
  )
}

export default DeleteAccount