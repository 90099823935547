// DoughnutChartWithAnimation.js
import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// Register components with ChartJS
ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChartWithAnimation = () => {
 

  const options = {
    animations: {
      tension: {
        duration: 1000,
        easing: 'easeInOutElastic',
        from: 0.5,
        to: 0,
        loop: true,
      },
    },
    hover: {
      animationDuration: 500,
    },
    plugins: {
      tooltip: {
        enabled: true,
      },
      
      legend: {
        display: true,
        position: 'right',
        labels:{
          font:{
            size:14,
            weight: 'bold',
            family: 'sans-serif', 
          },
          color:"#000",
          boxWidth:16,
          boxHeight:16,
          padding:10,
        }
      },
    },
    
  };

  return (
   
     
        <Doughnut data={{
          labels: ['Bounce', 'Visitor', 'Registered'],
          datasets: [
            {
              label: 'Dataset',
              data: [300, 50, 100],
              backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)'],
              hoverOffset: 8,
              spacing:5
            },
          ],
        }} options={options} width={200} height={200}/>
  
  );
};

export default DoughnutChartWithAnimation;
