import React from 'react';
import { Row, Col, Typography, Layout, Statistic, Card, ConfigProvider, Flex } from 'antd';
import {DollarOutlined, ArrowUpOutlined, ContainerFilled,PropertySafetyFilled,FundViewOutlined, GithubFilled } from '@ant-design/icons'
import CountUp from 'react-countup';
import DoughnutChart from './DoughnutChart'

const { Meta } = Card;
const { Title, Paragraph } = Typography;
const { Content } = Layout;
const formatter = (value) => <CountUp end={value} separator="," />;

const DashboardInfo = () => {
  return (
    <ConfigProvider
    theme={{
      components:{
        Statistic:{
          titleFontSize:24,
        },
      },
      token:{
        paddingLG:16
      }
    }}
    >

    <Layout >
      <Content>
        <Title level={3} style={{ textAlign: 'left', textOverflow:"clip" }}>Your Dashboard is Updated</Title>
        
        <Row gutter={[32, 32]} justify="space-evenly" >

          <Col xs={24} sm={24} md={14} lg={6}  >  
            <Card hoverable style={{height:500, backgroundColor:"#ffded4",}}>
              <Statistic
              title={<Title level={3} style={{ textAlign: 'left', textOverflow:"clip", }}>Total Income</Title>} 
              value={9112893} formatter={formatter} valueStyle={{fontSize:18}} />
               <div style={{
          position: 'absolute',
          bottom: 16,  
          right: 24,  
          fontSize: 36,
          color: '#f5f5f5' 
        }}>
              <DollarOutlined />
              </div>
            </Card>
          </Col>
         
          
          <Col xs={24} sm={24} md={14} lg={6} style={{display:"flex",flexDirection:"column", justifyContent:"space-between"}} >
        
          <Card
              hoverable
              style={{
                backgroundColor:'#e9e2f5',
         
                height:240,
                position: 'relative', 
              }}
              >  
            <Statistic
              title={<Title level={3} style={{ textAlign: 'left', textOverflow:"clip", }}>Total Sales</Title>} 
              value={1393} formatter={formatter} valueStyle={{fontSize:18}} />
               <div style={{
          position: 'absolute',
          bottom: 16,  
          right: 16,
          left:16,  
          fontSize: 36,
          color: '#f5f5f5',
          display:"flex",
          justifyContent:"space-between",
          alignItems:"end"
        }}>
          <Statistic
        
          value={11.28}
          precision={2}
          valueStyle={{
            color: '#3f8600',
          }}
          prefix={<ArrowUpOutlined />}
          suffix="%"
        />
            <ContainerFilled />
              </div>
          </Card>

          <Card
              hoverable
              style={{
                backgroundColor:'#d0ecf5',
              
                height:240,
                position: 'relative', 
              }}
              >  
            <Statistic
              title={<Title level={3} style={{ textAlign: 'left', textOverflow:"clip", }}>Total Sales</Title>} 
              value={1193} formatter={formatter} valueStyle={{fontSize:18}} />
               <div style={{
          position: 'absolute',
          bottom: 16,  
          right: 16,
          left:16,  
          fontSize: 36,
          color: '#f5f5f5',
          display:"flex",
          justifyContent:"space-between",
          alignItems:"end"
        }}>
          <Statistic
        
          value={8}
          precision={2}
          valueStyle={{
            color: '#3f8600',
          }}
          prefix={<ArrowUpOutlined />}
          suffix="%"
        />
             <PropertySafetyFilled />
              </div>
          </Card>
          
          </Col>
          
          
          <Col xs={24} sm={24} md={14} lg={6}>
          <Card
              hoverable
              style={{
                height:500,
                backgroundColor:'#d6efee',
              
              }}
              >
           <Statistic
              title={<Title level={3} style={{ textAlign: 'left', textOverflow:"clip", }}>Total New Users</Title>} 
              value={112893} formatter={formatter} valueStyle={{fontSize:32,fontWeight:"bolder", textAlign:"center", position:"absolute", left:"50%", top:"50%", transform:"translate(-50%, -50%"}} />
               <div style={{
          position: 'absolute',
          bottom: 16,  
          right: 24,  
          fontSize: 36,
          color: '#f5f5f5' 
        }}>
              <GithubFilled />
              </div>
          
          </Card>
          </Col>

          <Col xs={24} sm={24} md={14} lg={6}>
          <Card
              hoverable
              style={{
                height:500,
                backgroundColor:'#e3e3e3',
               
              }}
              >

           
            <Title level={3} style={{ textAlign: 'left', textOverflow:"clip" }}>Market Insights</Title>
            <div style={{
          position: 'absolute',
          top: 32,  
          right: 24,  
          fontSize: 36,
          color: '#f5f5f5' 
        }}>
            <FundViewOutlined />
              </div>
    <DoughnutChart/>
            </Card>
            
          </Col>
        
        </Row>
      </Content>
    </Layout>
              </ConfigProvider>
  );
};

export default DashboardInfo;
