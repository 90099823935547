import React from 'react';
import { Radio, Form} from "antd";
const CustomFormRadioButton = ({formLabel,handleChange, defaultValue, size, selectedValue, data,formName}) => {
        return (
            <Form.Item label={formLabel} name={formName} initialValue={selectedValue}>
            <Radio.Group
            value={selectedValue}
            defaultValue={defaultValue}
            onChange={handleChange}
            size={size}
            buttonStyle="solid"
            style={{ display: "flex",}}
            >
            {data.map((item, index)=><Radio.Button style={{height:"auto",}} value={item} key={index}>{item}</Radio.Button>)}  
            </Radio.Group>
            </Form.Item> 
        )
}

export default CustomFormRadioButton