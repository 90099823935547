import React from "react";
import CustomFormTextField from "./CustomFormTextField";
import { Form, Input, Checkbox } from "antd";
import CustomFormSelectDropDown from "./CustomFormSelectDropDown";
import { statesListData } from "../../Dummy Data/dummyData";

const PersoalDetailsForm = ({name, email, address, handleTextChange,state,phoneNumber,handleValuesChange, handlePhoneNumberChange,handleMMPVerified,checkVerifiedByMMP}) => {
    return (
        <>
            <CustomFormTextField
              label={"Name"}
              name={"name"}
              required={true}
              maxLength={100}
              errorMessage={"Please enter the Name."}
              showCount={false}
              value={name}
              placeholder={"Enter Name."}
              onChange={(event) => handleTextChange(event, "name")}
            />

        <Form.Item
              label="Phone Number"
              name="phoneNumber"
              rules={[
                { required: true, message: 'Please input your phone number!' },
                {pattern: /^\d{10}$/, message: 'Phone number must be in digits long' }
              ]}
            >
      
          <Input prefix="+91" value={phoneNumber} maxLength={10} 
          onChange={handlePhoneNumberChange}
          
          />
 
      </Form.Item>

      <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Please input your email!' },
            { 
              type: 'email', 
              message: 'The input is not valid E-mail!' 
            }
          ]}
        >
          <Input placeholder="Enter your email" style={{ width:'100%' }} maxLength={50}
          value={email}
          onChange={(event) => handleTextChange(event, "email")}
          />
        </Form.Item>

        <CustomFormTextField
            label={"Address"}
            name={"address"}
            required={true}
            maxLength={1000}
            errorMessage={"Please enter the Address."}
            showCount={false}
            value={address}
            placeholder={"Please enter the Address."}
            onChange={(event) => handleTextChange(event, "address")}
          />


            <CustomFormSelectDropDown
                data={statesListData}
                value={state}
                name={"state"}
                defaultValue={statesListData[0].label}
                handleChange={(e)=>handleValuesChange(e, "state")}
                label={"State"}
                message={"Please select the state."}
                required={true}
            />


            <Form.Item name={"VerifiedbyMMP"} required label="Verified by MMP" valuePropName="checked"
            initialValue={checkVerifiedByMMP}
            >
              <Checkbox
                  checked={checkVerifiedByMMP}
                  onChange={handleMMPVerified}
                  defaultChecked={checkVerifiedByMMP}
                  
                />
            </Form.Item>
        </>
    )
}

export default PersoalDetailsForm;