import React from "react";
import CustomFormRadioButton from "./CustomFormRadioButton";
import CustomFormNumberField from "./CustomFormNumberField";
import CustomFormSelectDropDown from "./CustomFormSelectDropDown";

const LandAndPlotsForm = ({
  listedByData,
  listedBy,
  plotArea,
  plotLength,
  plotBreadth,
  facingData,
  facing,
  handleSelectedButtonType,
  handleValuesChange,
  handleSelectFacing,
}) => {
  return (
    <>
      {/* Listed By */}
      <CustomFormRadioButton
        data={listedByData}
        defaultValue={listedByData[0]}
        formLabel={"Listed By"}
        selectedValue={listedBy}
        size={"large"}
        formName={"listedBy"}
        handleChange={(event) => handleSelectedButtonType(event, "listedBy")}
      />

      {/* Plot area */}
      <CustomFormNumberField
        label={"Ploat Area"}
        required={true}
        inputType={"number"}
        placeholder={"Please enter the plot area."}
        errorMessage={"Plot Area is mandatory."}
        size={"large"}
        formName={"ploatArea"}
        onChange={(value) => handleValuesChange(value, "plotArea")}
        value={plotArea}
        maxNumber={10000000}
      />

      {/* Length */}
      <CustomFormNumberField
        label={"Length"}
        formName={"length"}
        required={false}
        inputType={"number"}
        placeholder={"Please enter the length."}
        errorMessage={""}
        size={"large"}
        onChange={(value) => handleValuesChange(value, "plotLength")}
        value={plotLength}
        maxNumber={10000000}
      />

      {/* Breadth */}
      <CustomFormNumberField
        label={"Breadth"}
        formName={"breadth"}
        required={false}
        inputType={"number"}
        placeholder={"Please enter the breadth."}
        errorMessage={""}
        size={"large"}
        onChange={(value) => handleValuesChange(value, "plotBreadth")}
        value={plotBreadth}
        maxNumber={10000000}
      />

      {/* Facing */}
      <CustomFormSelectDropDown
        data={facingData}
        name={"facing"}
        value={facing}
        defaultValue={facingData[0]}
        handleChange={handleSelectFacing}
        label={"Facing"}
        message={""}
        required={false}
      />
    </>
  );
};

export default LandAndPlotsForm;
