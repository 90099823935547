import React,{useState} from 'react'
import {ConfigProvider, Layout, Card, Carousel, Image, Space, Badge, Tag, Flex, Typography,Row,Popconfirm } from 'antd'
import { IdcardOutlined, CheckOutlined,MailOutlined ,ArrowLeftOutlined,CloseOutlined,VerifiedOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import { Avatar, Button, notification } from "antd";
import { approveCategoryProperty, verifyProperty,rejectCategoryProperty, mmpVerifiedCategoryProperty } from "../Auth/properties";
import NoImgFound from "../assets/noimg.png";
import {keysToDisplay} from "../Dummy Data/dummyData"

const {Content} = Layout
const {Text} = Typography

const SiteInfo = ({ property, setIsList }) => {

    const [loading, setLoading] =useState(false);
    const [checkMMPVerified,setcheckMMPVerified] = useState(true);
    // const [checkButton,setCheckButton] = useState(!property.isRejected)
    const [checkApproved,setCheckApproved] = useState(property.isDraft)
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, head, content) => {
      api[type]({
        message: head,
        description: content,
      });
    };
    function excludeKeysAndReturnKeyValues(obj, keysToExclude) {
      return Object.entries(obj)
          .filter(([key, value]) => keysToExclude?.includes(key) && value !==undefined && value !=="" && value !==null)
          .map(([key, value]) => ( { key, value }));
  }
  
const confrimApprove = async (property) => {
  try {
    // isReject false to approve
    let response = await approveCategoryProperty({id:property?._id,status:false});
    if (response) {
      let response1 = await rejectCategoryProperty({id:property?._id,status:false});
    let signres = response?.err;
      openNotificationWithIcon(
        signres ? "error" : "success",
        signres ? "Error" : "Success",
        response?.msg
      );
      if(!signres){
        setCheckApproved(false)
      }
    }else{
    openNotificationWithIcon('error','Error','Please try again after sometime')
    }
    setLoading(loading)
  } catch (error) {
    console.log(error)
    openNotificationWithIcon('error','Error','Please try again after sometime')
    setLoading(loading)
  }

}

const cancel = (e) => {
  console.log(e);
  // message.error('Cancel Delete Request');
};

const rejectSiteHandler = async (property) => {

  try {
    // isReject true to reject
    let response = await rejectCategoryProperty({id:property?._id,status:true});
    if (response) {
      let response1 = await approveCategoryProperty({id:property?._id,status:true});
    let signres = response?.err;
      openNotificationWithIcon(
        signres ? "error" : "success",
        signres ? "Error" : "Success",
        response?.msg
      );
      // if(!signres){
      //   setCheckButton(false)
      // }
    }else{
    openNotificationWithIcon('error','Error','Please try again after sometime')
    }
    setLoading(loading)
  } catch (error) {
    console.log(error)
    openNotificationWithIcon('error','Error','Please try again after sometime')
    setLoading(loading)
  }
}

const verfiyMMPHandler = async (property) => {
  try {
    let response = await mmpVerifiedCategoryProperty({id:property?._id,status:false});
    if (response) {
    let signres = response?.err;
      openNotificationWithIcon(
        signres ? "error" : "success",
        signres ? "Error" : "Success",
        response?.msg
      );
      if(!signres){
        setCheckApproved(false);
        setcheckMMPVerified(false)
        // setIsList(true)
      }
    }else{
    openNotificationWithIcon('error','Error','Please try again after sometime')
    }
    setLoading(loading)
  } catch (error) {
    console.log(error)
    openNotificationWithIcon('error','Error','Please try again after sometime')
    setLoading(loading)
  }
}
  
  
  return (
    <ConfigProvider
      theme={{
        components: {
          Card:{
            colorBgContainer:"#f7f7f7"
          }
        },
      }}
    >
      <Layout>
        <Content style={{ }}>
        {contextHolder}
        <Badge.Ribbon
                          style={{
                            fontWeight: "600",
                            display: property?.isRejected
                              ? "flex"
                              : property?.verifiedByMMP
                              ? "flex"
                              : "none",
                          }}
                          text={
                            property?.isRejected
                              ? "Rejected"
                              : property?.verifiedByMMP
                              ? "MMP Verified"
                              : ""
                          }
                          color={property?.isRejected ? "red" : "green"}
                        >
        <Card style={{width:"100%", height:"100%",}}
        actions={[  <Space style={{display:"flex", justifyContent:"flex-end", margin:8}}>
            <Button type="link" onClick={() => setIsList(true)}  icon={<ArrowLeftOutlined />} size={"large"}>Back to List</Button>
            <Popconfirm
                                title="Reject"
                                description="Are you sure to reject this site?"
                                onConfirm={()=>rejectSiteHandler(property)}
                                onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                                icon={<CloseOutlined style={{ color: "red" }} />}
                                placement="top"
                              ><Button type='dashed'  style={{color:"red", border:"1px dashed red"}} icon={<CloseOutlined />} size={"large"}>Reject</Button></Popconfirm> 
           {
  !checkApproved && !property?.verifiedByMMP && checkMMPVerified &&
  <Popconfirm
                                title="MMP Verified"
                                description="Are you sure Verified MMP of this site?"
                                onConfirm={()=>verfiyMMPHandler(property)}
                                onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                                icon={<QuestionCircleOutlined style={{ color: "orange" }} />}
                                placement="top"
                              >
  <Button
  loading={loading}
    type='default'
    size='large'
    icon={<VerifiedOutlined />}
    style={{color:"orange",border:"1px dashed orange"}}
  
  >
    MMP Verified
  </Button></Popconfirm>}
            { checkApproved &&   <Popconfirm
                                title="Approve"
                                description="Are you sure to appriove this site?"
                                onConfirm={()=>confrimApprove(property)}
                                onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                                icon={<CheckOutlined style={{ color: "green" }} />}
                                placement="top"
                              ><Button type="primary"     loading={loading}  style={{background:"green", }} icon={<CheckOutlined />} size={"large"}>Approve</Button></Popconfirm>}
          
            </Space>]}
        >
            <Row>
            <Space style={{  }} >
                    <Image
                     width={450}
                     height={380}
                      alt="property image"
                      src={property?.imageUrl[0]?.length > 0 ? property?.imageUrl[0].split('?')[0] : NoImgFound}
                    />
            </Space>
            <div style={{ height: "100%", margin:"0px 16px", }}>
  
                              <Space
                                direction="horizontal"
                                size={8}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexWrap: "wrap",
                                  lineHeight: "1.2",
                                }}
                              >
                                 <Text strong style={{ fontWeight: "bold", fontSize:32 }}>
                                  {property?.title}
                                </Text>

                              </Space>
                             
                              <Space
                                direction="horizontal"
                                size={8}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexWrap: "wrap",
                                  lineHeight: "1.2",
                                }}
                              >
                                 <Text  style={{  fontSize:18 }}>
                                  {property?.categoryType}
                                </Text>
                                <Space
                                  align="baseline"
                                  style={{ display: "flex" }}
                                >
                                  <Badge
                                    color="red"
                                    size='small'
                                    style={{
                                      height: 7,
                                      width: 7,
                                      borderRadius: "50%",
                                    }}
                                  />
                                  <Text style={{ fontSize: 18 }}>
                                    For {property?.lookingTo}
                                  </Text>
                                </Space>

                              </Space>
                            
                              <Space
                                style={{
                                  margin: "8px 0",
                                  color: "#787878",
                                  flexWrap: "wrap",
                                  lineHeight: "1.2",
                                }}
                              ><IdcardOutlined style={{fontSize:18}} />
                                <Text
                                  style={{
                                    whiteSpace: "break-spaces",
                                    overflow: "",
                                    textOverflow: "ellipsis",
                                    fontSize:18,
  
                                  }}
                                >
                                  {property?.address}{", "} {property?.state}
                                </Text>
                              </Space>

                             

                              <Space
                                direction="horizontal"
                                size="large"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  margin: "10px 0",
                                  flexWrap: "wrap",
                                 
                                }}
                              >
                                <Text style={{fontSize:18, fontWeight:"bold"}}>₹{property?.setPrice}</Text>
                              </Space>

                         
           
         
          <div style={{ display: "flex", alignItems: "center", margin: "8px 0" }}>
            <div style={{ marginRight: 6 }}>
              <Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=8" size={64}/>
            </div>
            <Space direction='vertical' align='baseline'>
              <Text style={{fontSize:16}}>Posted By: <Text style={{ fontWeight: "bold",fontSize:18}}>{property?.postedBy?.username}</Text></Text>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Text style={{ marginRight: 6 }}>
                <MailOutlined style={{fontSize:18}}/>
                </Text>
                <Text style={{ margin: "5px 0", color: "#787878" ,fontSize:18}}>
                  {property?.postedBy?.email}
                </Text>
              </div>
            </Space>
          </div>
          </div>
         
           
         </Row>    
          <Flex gap="16px 0" wrap style={{margin:"8px 0px"}}>
                            
                            {excludeKeysAndReturnKeyValues(property, keysToDisplay(property?.categoryType, property?.lookingTo, property?.subCategoryType || undefined)).map((item) => (
                              <Tag
                                color={"#FF4D4F"}
                                style={{
                                  borderRadius: 20,
                                  fontWeight: "bold",
                                  color: "#fff",
                                  fontSize:18,
                                  padding:8,
                                  textTransform:"capitalize"
                                }}
                                key={item?.key}
                              >
                                   {item?.key}: {typeof item?.value === 'boolean' ?  item?.value ? "Yes" : "No" : item?.value}
                              </Tag>
                            ))}
                          </Flex>
          <div>
            <Text style={{ fontWeight: "bold", margin: "5px 0" }}>
              Description
            </Text>
          </div>
          <Text style={{ color: "#787878" }}>{property?.description}</Text>
           
          
            <div style={{display:property?.imageUrl.length > 0 ?"flex" : "none"}}>
            <Text style={{ fontWeight: "bold", marginTop: 10 }}>Gallery</Text>
            </div>
          <Space style={{width:"100%", flexWrap:"wrap"}}>
            {property?.imageUrl.map((ele) => {
                return (
                  <div style={{ margin: 5 }}>
                    <Image
                      style={{ borderRadius: 5, width: 200, height: 200 }}
                      alt="example"
                      src={ele?.split('?')[0]}
                    />
                </div>
              );
            })}
          </Space>
        
        </Card>
        </Badge.Ribbon>
        </Content>
        </Layout>
        </ConfigProvider>
  )
}

export default SiteInfo