import React, { useState } from "react";
import "./Login&signup.css";
import { Button, Input, notification } from "antd";
import {signin, signup, isAuthenticated} from '../Auth/auth'
import {
  UserOutlined,
  MailOutlined,
  UnlockOutlined
} from "@ant-design/icons";
import Logo from "./Assets/Realestatelogo.png";
import image1 from "./Assets/Realestate2.png";
import { useNavigate, Navigate } from "react-router-dom";

function LoginSignup() {
  const navigate = useNavigate();

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type,head,content) => {
    api[type]({
      message: head,
      description:content,
    });
  };

  const [isSignup, setIsSignup] = useState(false); // Start on the Signup page

  const [signupValue, setSignupValue] = useState({
    email: '',
    username:'',
    password: '',
    redirectToReferrer: false,
  });

  const [signinValue, setSigninValue] = useState({
    username:'',
    password: '',
    redirectToReferrer: false,
  });

  // const { user } = isAuthenticated();

  const toggleForm = () => setIsSignup((prev) => !prev)

  const onChangeSignup = (e) => setSignupValue({ ...signupValue, [e.target.name]: e.target.value })

  const onChangeSignIn = (e) => setSigninValue({ ...signinValue, [e.target.name]: e.target.value })

  const signinApi = async() =>{
    try {
      const response = await signin({ username:signinValue.username, password:signinValue.password, })
      if(response){
        let signres = response?.err;
        if(response?.user?.role === 0){
          openNotificationWithIcon('error','Error',"Don't have admin access!")
        }else{
          openNotificationWithIcon(signres ? 'error':'success',signres ? 'Error':'Success',response?.msg)
          if(!signres){
            await localStorage.setItem('userToken',response?.token)
            await localStorage.setItem('userDetails',JSON.stringify(response))
            return navigate('/dashboard');
          }
        }
      }else{
        openNotificationWithIcon('error','Error','Please try again after sometime')
      }
    } catch (error) {
      console.log(error)
      openNotificationWithIcon('error','Error','Please try again after sometime')
    }
  }

  const signupApi = async() =>{
    try {
      const response = await signup({ username:signupValue.username,email:signupValue.email, password:signupValue.password,role:1 })
      if(response){
        let signUpRes = response?.err;
        openNotificationWithIcon(signUpRes ? 'error':'success',signUpRes ? 'Error':'Success',response?.msg)
        if(!signUpRes){
          setSignupValue({
            email: '',
            username:'',
            password: '',
            redirectToReferrer: false,
          })
          setIsSignup(false)
        }
      }else{
        openNotificationWithIcon('error','Error','Please try again after sometime')
      }
    } catch (error) {
      console.log(error)
      openNotificationWithIcon('error','Error','Please try again after sometime')
    }
  }

  const onSubmitLogin = (e) => {
    e.preventDefault();
    return signinApi()
  };

  const onSubmitSignUp = (e) => {
    e.preventDefault();
    return signupApi()
  };

  const redirectUser = () => {
    if(isAuthenticated() && isAuthenticated()?.user?.role === 1){
      return <Navigate to="/dashboard" replace />
    }
  };


  return (
    <div className="App">
      {contextHolder}
      {redirectUser()}
      <div className="logincontainer">
        <div className="headercontent">
          <img src={image1} className="App-logo1" alt="logo" />

          <div>
            <span
              style={{
                color: "GrayText",
                fontWeight: "initial",
                fontSize: "18px",
              }}
            >
              HOME
            </span>
          </div>
          <div>
            <span
              style={{
                color: "GrayText",
                fontWeight: "initial",
                fontSize: "18px",
              }}
            >
              ABOUT US
            </span>
          </div>
          <div>
            <span
              style={{
                color: "GrayText",
                fontWeight: "initial",
                fontSize: "18px",
              }}
            >
              CONTACT US
            </span>
          </div>
        </div>

        <div style={{marginTop:'50px'}}>
          <Button
            type="text"
            style={{
              fontWeight: "bolder",
              fontSize: "22px",
              // color: "lightgray",
              color: isSignup ? "gray" : "#1677ff",
              textDecoration: isSignup ? "none" : "underline",
              // marginLeft:'100px',
              // marginRight: "-120px",
            }}
            onClick={toggleForm}
          >
            {" "}
            Log in{" "}
          </Button>

          <Button
            type="text"
            style={{
              fontWeight: "bolder",
              fontSize: "22px",
              // color: "lightgray",
              textDecoration: isSignup ? "underline" : "none",
              color: isSignup ? "#1677ff" : "gray",
              // marginRight: "150px",
            }}
            onClick={toggleForm}
          >
            Sign up
          </Button>
        </div>
        
        {!isSignup ? (
          <div className="login-form">
            {/* <h2>Login</h2> */}
            <form
            onSubmit={onSubmitLogin}
              // className="signupformdiv"
              style={{
                // marginLeft: "180px",
                // justifyContent: "center",
                // alignItems: "center",
              }}
            >
              <Input
                placeholder="Username"
                name="username"
                value={signinValue.username}
                onChange={onChangeSignIn}
                variant="filled"
                style={{ marginLeft: "0px", height: "40px", margin: "20px 0" }}
                prefix={<UserOutlined style={{ color: "#1677ff" }} />}
                required
              />
              <Input
              required
              type='password'
              name="password"
              value={signinValue.password}
              onChange={onChangeSignIn}
                placeholder="Paassword"
                variant="filled"
                style={{ marginLeft: "0px", height: "40px", margin: "20px 0" }}
                prefix={<UnlockOutlined style={{ color: "#1677ff" }} />} // Add the key icon
                // style={{ marginBottom: '10px', width: '80%', margin: '0 auto' }}
              />
              {/* <button type="submit">Login</button> */}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: "20px",
                }}
              >
                {/* <text
                  style={{ margin: "10px", height: "40px", fontSize: "18px" }}
                >
                  Forgot your Paassword?
                </text> */}
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "25%", margin: "10px", height: "30px" }}
                  // onClick={() => navigate("/dashboard")}
                >
                  Log In
                </Button>
              </div>
            </form>
          </div>
        ) : (
          <div className="signup-form">
            {/* <h2>Signup</h2> */}
            <form
            onSubmit={onSubmitSignUp}
              // className="signupformdiv"
              style={{
                // marginLeft: "180px",
                // justifyContent: "center",
                // alignItems: "center",
              }}
            >
              <Input
              required
                placeholder="Username"
                name="username"
                value={signupValue.username}
                onChange={onChangeSignup}
                variant="filled"
                style={{ marginLeft: "0px", height: "40px", margin: "20px 0" }}
                prefix={<UserOutlined style={{ color: "#1677ff" }} />}
              />
                            <Input
              required
              type='email'
                placeholder="Email"
                name="email"
                value={signupValue.email}
                onChange={onChangeSignup}
                variant="filled"
                style={{ marginLeft: "0px", height: "40px", margin: "20px 0" }}
                prefix={<MailOutlined style={{ color: "#1677ff" }} />}
              />
              <Input
              required
              type='password'
                placeholder="Paassword"
                name="password"
                value={signupValue.password}
                onChange={onChangeSignup}
                variant="filled"
                style={{ marginLeft: "0px", height: "40px", margin: "20px 0" }}
                prefix={<UnlockOutlined style={{ color: "#1677ff" }} />} // Add the key icon
                // style={{ marginBottom: '10px', width: '80%', margin: '0 auto' }}
              />

              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%", margin: "10px 0", height: "40px" }}
              >
                Sign Up
              </Button>
            </form>
          </div>
        )}
      </div>

    <div className="login-banner">
        <img src={Logo} className="banner-inner-img" alt="logo" />
    </div>
    </div>
  );
}

export default LoginSignup;