import { API } from './config';

// add properties
export const addPropertiesApi = (data) => {
  return fetch(`${API}/api/properties/addpost`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      return err
    });
};

//post properties
export const postPropertiesApi = (data) => {
  return fetch(`${API}/api/properties/post/property`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      return err
    });
};

// get properties
export const getAllPropertiesList = (user) => {
  return fetch(`${API}/api/properties/list`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      return err
    });
};

// rejected properties
export const rejectProperty = (data) => {
    return fetch(`${API}/api/properties/isrejected`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        console.log(err);
        return err
      });
  };

  // verify properties
export const verifyProperty = (data) => {
  return fetch(`${API}/api/properties/isverify`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      return err
    });
};

// put properties
export const updatePropertiesList = (user) => {
    return fetch(`${API}/api/properties/update`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        console.log(err);
        return err
      });
  };

// get ALL properties
export const getProperties = () => {
  return fetch(`${API}/api/properties/role-list`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      return err
    });
};

export const deleteProperty = (data) => {
  return fetch(`${API}/api/properties/delete-property`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      return err
    });
};



//================================================================Category Post =================================================
export const createCategoryPostApi = (data) => {
  return fetch(`${API}/api/category/create-property`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      return err
    });
};

export const updateCategoryPostApi = (data) => {
  return fetch(`${API}/api/category/update-property`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      return err
    });
};

export const getCategoryPostsApi = () => {
  return fetch(`${API}/api/category/role-list`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      return err
    });
};

export const deleteCategoryPostsApi = (data) => {
  return fetch(`${API}/api/category/delete-property`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      return err
    });
};

export const approveCategoryProperty = (data) => {
  return fetch(`${API}/api/category/isapproved`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      return err
    });
};

export const rejectCategoryProperty = (data) => {
  return fetch(`${API}/api/category/isrejected`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      return err
    });
};

export const mmpVerifiedCategoryProperty = (data) => {
  return fetch(`${API}/api/category/isverify`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      return err
    });
};

//================================================================Category Post =================================================
