import React from "react";
import CustomFormRadioButton from "./CustomFormRadioButton";
import CustomFormNumberField from "./CustomFormNumberField";
import CustomFormSelectDropDown from "./CustomFormSelectDropDown";
import { facingData, FurnishedData, listedByData, projectStatusData, typeOfHouseData } from "../../Dummy Data/dummyData";
import {Form, Radio} from 'antd'


const HouseAndApartmentsForm = ({houseType, bhk, bathroom, furnished, saleType, projectStatus, listedBy, buildUpArea, carpetArea, isBachlorsAllowed, monthlyMaintenance, totalFloors, floorNumber, carParking, facing,handleSelectedButtonType, handleValuesChange, handleSelectFacing }) => {
    return (
      <>
                  {/* Houuse Type */}
                  <CustomFormRadioButton
              formName={"subCategoryType"}
              data={typeOfHouseData}
              defaultValue={typeOfHouseData[0]}
              formLabel={"Type"}
              selectedValue={houseType}
              size={"large"}
              handleChange={(event) =>
                handleSelectedButtonType(event, "houseType")
              }
            />

            {/* BHK */}
            <CustomFormRadioButton
              data={['1', '2', '3', '4', "4+"]}
              defaultValue={'1'}
              formLabel={"BHK"}
              selectedValue={bhk}
              size={"large"}
              handleChange={(event) => handleSelectedButtonType(event, "bhk")}
              formName={"noofBedrooms"}
            />

            {/* Bath Rooms */}
            <CustomFormRadioButton
            formName={"noofBathrooms"}
              data={['1', '2', '3', '4', "4+"]}
              defaultValue={'1'}
              formLabel={"Bathrooms"}
              selectedValue={bathroom}
              size={"large"}
              handleChange={(event) => handleSelectedButtonType(event, "bathrooms")}
            />

            {/* Furnishing */}
            <CustomFormRadioButton
              data={FurnishedData}
              defaultValue={FurnishedData[0]}
              formLabel={"Furnishing"}
              formName={"furnishing"}
              selectedValue={furnished}
              size={"large"}
              handleChange={(event) =>
                handleSelectedButtonType(event, "furnishing")
              }
            />

            {/* Project Status */}
            {saleType.toLowerCase() === "sell" && <CustomFormRadioButton
              data={projectStatusData}
              defaultValue={projectStatusData[0]}
              formLabel={"Project Status"}
              formName={"projectStatus"}
              selectedValue={projectStatus}
              size={"large"}
              handleChange={(event) =>
                handleSelectedButtonType(event, "projectStatus")
              }
            />}

            {/* Listed By */}
            <CustomFormRadioButton
              data={listedByData}
              formName={"listedBy"}
              defaultValue={listedByData[0]}
              formLabel={"Listed By"}
              selectedValue={listedBy}
              size={"large"}
              handleChange={(event) =>
                handleSelectedButtonType(event, "listedBy")
              }
            />

            {/* Super Builtup area */}
            <CustomFormNumberField
            formName={"builtupArea"}
              label={"Super Builtup area sqft"}
              required={true}
              inputType={"number"}
              placeholder={"Please enter the super built up area."}
              errorMessage={"Super Builup area sqft is mandatory."}
              size={"large"}
              onChange={(value) =>
                handleValuesChange(value, "superBuiltUpArea")
              }
              value={buildUpArea}
              maxNumber={10000000}
            />

            {/* Carpet Area */}
            <CustomFormNumberField
              label={"Carpet Area sqft"}
              required={true}
              formName={"carpetArea"}
              inputType={"number"}
              placeholder={"Please enter the carpet area."}
              errorMessage={"Carpet Area sqft is mandatory."}
              size={"large"}
              onChange={(value) => handleValuesChange(value, "carpetArea")}
              value={carpetArea}
              maxNumber={10000000}
            />


          {/* Bachelors Allowed*/}
          {(saleType.toLowerCase() === "rent" || saleType.toLowerCase() === "rent/lease") &&   
            
            <Form.Item label={'Bachelors Allowed'} name={'bachelorsAllowed'} initialValue={isBachlorsAllowed}>
            <Radio.Group
            value={isBachlorsAllowed}
            // defaultValue={false}
            onChange={(event) =>
              handleSelectedButtonType(event, "bachlorsAllowed")
            }
            size={'large'}
            buttonStyle="solid"
            style={{ display: "flex",}}
            >
            <Radio.Button style={{height:"auto",}} value={true} >Yes</Radio.Button>
            <Radio.Button style={{height:"auto",}} value={false} >No</Radio.Button>
            </Radio.Group>
            </Form.Item> 
            
            }

            {/* Maintenance (Monthly) */}
            <CustomFormNumberField
              label={"Maintenance (Monthly)"}
              formName={"maintenance"}
              required={false}
              inputType={"number"}
              placeholder={"Please enter the amount."}
              errorMessage={""}
              size={"large"}
              onChange={(value) =>
                handleValuesChange(value, "monthlyMaintenance")
              }
              value={monthlyMaintenance}
              maxNumber={10000000}
            />

            {/* Total Floors */}
            <CustomFormNumberField
            formName={"totalFloors"}
              label={"Total Floors"}
              required={false}
              inputType={"number"}
              placeholder={"Please enter the floor numbers."}
              errorMessage={""}
              size={"large"}
              onChange={(value) => handleValuesChange(value, "totalFloors")}
              value={totalFloors}
              maxNumber={1000}
            />

        
            {/* Floor Number */}
            <CustomFormNumberField
            formName={"floorNo"}
              label={"Floor No"}
              required={false}
              inputType={"number"}
              placeholder={"Please enter the floor number."}
              errorMessage={""}
              size={"large"}
              onChange={(value) => handleValuesChange(value, "floorNo")}
              value={floorNumber}
              maxNumber={1000}
            />

           

              {/* Car Parking */}
              <CustomFormRadioButton
              formName={"carParking"}
            data={['0', '1', '2', '3', "3+"]}
            defaultValue={'1'}
            formLabel={"Car Parking"}
            selectedValue={carParking}
            size={"large"}
            handleChange={(event) =>
              handleSelectedButtonType(event, "carParking")
            }
          />

           {/* Facing */}
           <CustomFormSelectDropDown
           name={"facing"}
              data={facingData}
              value={facing}
              defaultValue={facingData[0]}
              handleChange={handleSelectFacing}
              label={"Facing"}
              message={""}
              required={false}
            />

      </>
    )
  };

export default HouseAndApartmentsForm