// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-properties-form h5{
    margin-bottom: 2px;
}

.addproperties-row1{
    display:flex;
    margin-bottom:10;
}

.add-properties_upload-cover-img{
    display: flex;
    flex-wrap: wrap;
}

.add-properties-form textarea{
    width: 630px !important;
}

textarea:where(.css-dev-only-do-not-override-ftxhvs).ant-input{
    max-width: 700px;
}

@media screen and (max-width: 996px) {
    .addproperties-row1{
        flex-direction: column;
    }
  }

  @media screen and (max-width: 940px) {
    .add-properties-form textarea{
        width: 412px !important;
    }
  }

  @media screen and (max-width: 690px) {
    .add-properties-form textarea{
        width: 190px !important;
    }
  }`, "",{"version":3,"sources":["webpack://./src/AddProperties/addproperties.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,sBAAsB;IAC1B;EACF;;EAEA;IACE;QACI,uBAAuB;IAC3B;EACF;;EAEA;IACE;QACI,uBAAuB;IAC3B;EACF","sourcesContent":[".add-properties-form h5{\n    margin-bottom: 2px;\n}\n\n.addproperties-row1{\n    display:flex;\n    margin-bottom:10;\n}\n\n.add-properties_upload-cover-img{\n    display: flex;\n    flex-wrap: wrap;\n}\n\n.add-properties-form textarea{\n    width: 630px !important;\n}\n\ntextarea:where(.css-dev-only-do-not-override-ftxhvs).ant-input{\n    max-width: 700px;\n}\n\n@media screen and (max-width: 996px) {\n    .addproperties-row1{\n        flex-direction: column;\n    }\n  }\n\n  @media screen and (max-width: 940px) {\n    .add-properties-form textarea{\n        width: 412px !important;\n    }\n  }\n\n  @media screen and (max-width: 690px) {\n    .add-properties-form textarea{\n        width: 190px !important;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
