import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, Navigate, useLocation } from "react-router-dom";
// import Dashboard from "./Dashboard";
import LoginSignup from "./Realestate/LoginSignup";
import AdminRoute from "./Routes/AdminRoute";
import Dashboard from "./Dashboard/Dashboard";
import BannerImg from "./assets/banner.png";
import { isAuthenticated } from "./Auth/auth";

import {
  Layout,
  Flex,
  Menu,
  ConfigProvider,
  Avatar,
  Typography,
  Grid,
  Button,
  Breadcrumb,
  theme,
  Badge,
} from "antd";
import {
  DownOutlined,
  BranchesOutlined,
  LogoutOutlined,
  SettingOutlined,
  ScheduleOutlined,
  UsergroupAddOutlined,
  HomeOutlined,
  DesktopOutlined,
  ShopOutlined,
  RightOutlined,
  LeftOutlined,
  SearchOutlined,
  MessageOutlined,
  BellFilled,
  QuestionCircleOutlined,
  PhoneOutlined,
  DeleteOutlined,
  UserOutlined,
  TeamOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import AdminSites from "./Sites/AdminSites";
import Sites from "./Sites/Sites";
import Subscribedsites from "./Subscribedsites/Subscribedsites";
import AddNewProperty from "./AddProperties/AddNewProperty";
import Agents from "./Agents/agents";
import Plans from "./Plans/Plan";
import Settings from "./Settings/settings";
import DeleteAccount from "./Settings/DeleteAccount";
import ContactUs from "./Settings/ContactUs";
import FAQS from "./Settings/FAQs";
import Inspection from "./Settings/Inspection";
import DashboardInfo from "./Dashboard/DashboardInfo";

const { Header, Sider, Content, Footer } = Layout;
const { useBreakpoint } = Grid;

const userData = isAuthenticated();

const siderStyle = {
  color: "#f4f1f0",
  backgroundColor: "#f4f1f0",
  marginRight: 16,
  position: "relative",
};
const footerStyle = {
  textAlign: "center",
  color: "#fff",
  margin: 0,
  marginTop: 8,
  padding: 0,
};

const collapsibleButtonStyle = {
  position: "absolute",
  top: "3%",
  right: "-10px",
  transform: "translateY(-3%)",
  backgroundColor: "#f4f1f0",
  color: "#3375e3",
  border: "none",
  borderRadius: "4px",
  padding: "45px 4px",
  cursor: "pointer",
  zIndex: 9,
};

export default function App() {
  const [collapsed, setCollapsed] = useState(true);
  const [activeKey, setActiveKey] = useState("1");
  const [activeName, setActiveName] = useState("Dashboard");
  const [propertyDataForUpdate, setPropertyDataForUpdate] = useState(null);
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const location = useLocation();
  const calculateSiderWidth = () => {
    if (screens.lg) return 300;
    if (screens.md) return 250;
    return 250;
  };

  useEffect(() => {
    if (!screens.sm && !screens.md && !screens.sm) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [screens]);

  useEffect(() => {
   
    const pathToKeyMap = {
      "/dashboard": {key:"1", label:"Dashboard"},
      "/site/user-sites": {key:"12", label:"User Sites"},
      "/site/admin-sites":{key:"13", label:"Admin Sites"},
      "/subscribed-sites":{key:"3", label:"Subscribed Sites"},
      "/add-new-property":{key:"4", label:"Add New Property"},
      "/agents": {key:"5", label:"Agents"},
      "/plans": {key:"6", label:"Plans"},
      "/settings/admin-profile": {key:"8", label:"Admin Profile"},
      "/settings/delete-account": {key:"9", label:"Delete Account"},
      "/settings/contactus": {key:"10", label:"Contact US"},
      "/settings/faqs":{key:"11", label:"Faqs"},
      "/settings/inspection": {key:"14", label:"Inspection"},
    };
    
    const currentPath = location.pathname;
    const matchedKey = pathToKeyMap[currentPath]?.key || "1"; // Default to "1" for unknown paths
    setActiveKey(matchedKey);
    setActiveName(pathToKeyMap[currentPath]?.label || "Dashboard");
  }, [location]);


  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const checkIsSubMenu = () => {
    if (["8", "9", "10", "11", "12", "13", "14"].includes(activeKey)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Routes>
      <Route 
    path="/login" 
    element={<LoginSignup />} 
  />
<Route
    path="*"
    element={ isAuthenticated() ? (
    <ConfigProvider
      theme={{
        token: {
          colorBgBase: "#fff",
        },
        components: {
          Layout: {
            headerBg: "#fff",
            colorBgLayout: "#fff",
          },
          Menu: {
            fontSize: 14,
            itemBg: "#fff",
          },
        },
      }}
    >
      <Flex gap="middle" wrap style={{ height: "100vh" }}>
        <Layout
          style={{
            borderRadius: 8,
            margin: screens.md ? "16px 32px" : "4px 2px",
          }}
        >
          <Sider
            style={siderStyle}
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            trigger={null}
            width={calculateSiderWidth()}
          >
            <Button
              onClick={() => setCollapsed(!collapsed)}
              style={{
                display: screens.xs ? "none" : "flex",
                ...collapsibleButtonStyle,
              }}
            >
              {collapsed ? <RightOutlined /> : <LeftOutlined />}
            </Button>
            <div
              style={{
                backgroundColor: "#f4f1f0",
                height: "100%",
                borderRadius: 20,
              }}
            >
              <Flex vertical>
                <Flex
                  justify="center"
                  style={{ marginLeft: 16, marginRight: 16 }}
                >
                  <Avatar
                    shape="square"
                    size={48}
                    src={BannerImg}
                    style={{ marginTop: 32 }}
                    onClick={()=>{
                      setActiveKey("1");
                      setActiveName("Dashboard");
                      navigate("/dashboard");
                    }}
                  />
                </Flex>

                <Flex
                  justify="space-between"
                  align="center"
                  gap={"middle"}
                  style={{ marginTop: 32, marginLeft: 16, marginRight: 16 }}
                >
                  <Avatar
                    shape="circle"
                    style={{ backgroundColor: "#ff0000" }}
                    size={"large"}
                    src={"https://api.dicebear.com/7.x/miniavs/svg?ßseed=8"}
                  />

                  {!collapsed && (
                    <Typography
                      style={{
                        verticalAlign: "middle",
                        textAlign: "center",
                        fontFamily: "sans-serif",
                        fontWeight: "bold",
                        fontSize: 14,
                      }}
                    >
                      {userData?.user ? userData?.user.username : "Admin"}
                    </Typography>
                  )}

                  {!collapsed && (
                    <Avatar
                      shape="square"
                      style={{
                        backgroundColor: "#3375e3",
                        verticalAlign: "middle",
                      }}
                      size={24}
                      icon={<DownOutlined />}
                    />
                  )}
                </Flex>

                <Menu
                  style={{
                    backgroundColor: "#f4f1f0",
                    border: "none",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                    marginTop: 32,
                  }}
                  mode="inline"
                  selectedKeys={[activeKey]}
                  items={[
                    {
                      key: "1",
                      icon: (
                        <BranchesOutlined
                          size={"large"}
                          style={{ marginRight: 16, color: "#4096ff" }}
                        />
                      ),
                      label: "Dashboard",
                      style: { marginTop: 16 },
                      itemIcon: () => (
                        <RightOutlined
                          style={{ marginRight: 16, color: "#4096ff" }}
                        />
                      ),
                      onClick: () => {
                        setActiveKey("1");
                        setActiveName("Dashboard");
                        navigate("/dashboard");
                      },
                    },
                    {
                      key: "2",
                      icon: (
                        <ShopOutlined
                          style={{ marginRight: 16, color: "#4096ff" }}
                        />
                      ),
                      label: "Site",

                      children: [
                        {
                          key: "12",
                          label: "User Sites",
                          onClick: () => {
                            setActiveKey("12");
                            setActiveName("User Sites");
                            navigate("/site/user-sites"); 
                          },
                          icon: <TeamOutlined />,
                        },
                        {
                          key: "13",
                          label: "Admin Sites",
                          onClick: () => {
                            setActiveKey("13");
                            setActiveName("Admin Sites");
                            navigate("/site/admin-sites"); 
                          },
                          icon: <UserOutlined />,
                        },
                      ],

                      expandIcon: ({ isOpen }) =>
                        isOpen ? (
                          <DownOutlined
                            style={{
                              justifyContent: "flex-end",
                              color: "#4096ff",
                            }}
                          />
                        ) : (
                          <RightOutlined
                            style={{
                              justifyContent: "flex-end",
                              color: "#4096ff",
                            }}
                          />
                        ),
                    },
                    {
                      key: "3",
                      icon: (
                        <DesktopOutlined
                          style={{ marginRight: 16, color: "#4096ff" }}
                        />
                      ),
                      label: "Subscibed Site",
                      itemIcon: () => (
                        <RightOutlined
                          style={{ marginRight: 16, color: "#4096ff" }}
                        />
                      ),
                      onClick: () => {
                        setActiveKey("3");
                        setActiveName("Subscibed Site");
                        navigate("/subscribed-sites");
                      },
                    },
                    {
                      key: "4",
                      icon: (
                        <HomeOutlined
                          style={{ marginRight: 16, color: "#4096ff" }}
                        />
                      ),
                      label: "Add Property",
                      itemIcon: () => (
                        <RightOutlined
                          style={{ marginRight: 16, color: "#4096ff" }}
                        />
                      ),
                      onClick: () => {
                        setActiveKey("4");
                        setActiveName("Add Property");
                        navigate("/add-new-property");
                      },
                    },
                    {
                      key: "5",
                      icon: (
                        <UsergroupAddOutlined
                          style={{ marginRight: 16, color: "#4096ff" }}
                        />
                      ),
                      label: "Agents",
                      itemIcon: () => (
                        <RightOutlined
                          style={{ marginRight: 16, color: "#4096ff" }}
                        />
                      ),
                      onClick: () => {
                        setActiveKey("5");
                        setActiveName("Agents");
                        navigate("/agents");
                      },
                    },
                    {
                      key: "6",
                      icon: (
                        <ScheduleOutlined
                          style={{ marginRight: 16, color: "#4096ff" }}
                        />
                      ),
                      label: "Plans",
                      itemIcon: () => (
                        <RightOutlined
                          style={{ marginRight: 16, color: "#4096ff" }}
                        />
                      ),
                      onClick: () => {
                        setActiveKey("6");
                        setActiveName("Plans");
                        navigate("/plans");
                      },
                    },
                
                    {
                      key: "7",
                      icon: (
                        <SettingOutlined
                          style={{ marginRight: 16, color: "#4096ff" }}
                        />
                      ),
                      label: "Settings",

                      children: [
                        {
                          key: "8",
                          label: "Admin Profile",
                          onClick: () => {
                            setActiveKey("8");
                            setActiveName("Settings");
                            navigate("/settings/admin-profile");
                          },
                          icon: <UserOutlined />,
                        },
                        {
                          key: "9",
                          label: "Delete Account",
                          onClick: () => {
                            setActiveKey("9");
                            setActiveName("Delete Account");
                            navigate("/settings/delete-account");
                          },
                          icon: <DeleteOutlined />,
                        },
                        {
                          key: "10",
                          label: "Contact Us",
                          onClick: () => {
                            setActiveKey("10");
                            setActiveName("Contact Us");
                            navigate("/settings/contactus");
                          },
                          icon: <PhoneOutlined />,
                        },
                        {
                          key: "11",
                          label: "FAQs",
                          onClick: () => {
                            setActiveKey("11");
                            setActiveName("FAQs");
                            navigate("/settings/faqs");
                          },
                          icon: <QuestionCircleOutlined />,
                        },
                        {
                          key: "14",
                          label: "Inspection",
                          onClick: () => {
                            setActiveKey("14");
                            setActiveName("Inspection");
                            navigate("/settings/inspection");
                          },
                          icon: <FileSearchOutlined />,
                        },
                      ],

                      expandIcon: ({ isOpen }) =>
                        isOpen ? (
                          <DownOutlined
                            style={{
                              justifyContent: "flex-end",
                              color: "#4096ff",
                            }}
                          />
                        ) : (
                          <RightOutlined
                            style={{
                              justifyContent: "flex-end",
                              color: "#4096ff",
                            }}
                          />
                        ),
                    },
                  ]}
                />

                <Button
                  variant="link"
                  onClick={() => {
                    localStorage.clear();
                    window.location.reload();
                  }}
                  icon={<LogoutOutlined style={{ color: "#f55555" }} />}
                  style={{
                    color: "#f45555",
                    position: "absolute",
                    bottom: 32,
                    left: "50%",
                    transform: "translate(-50%)",
                  }}
                  color={"danger"}
                >
                  {collapsed ? "" : "Logout"}
                </Button>
              </Flex>
            </div>
          </Sider>
          <Layout>
            <Header
              style={{
                display: "flex",
                flexDirection: screens.md ? "row" : "column",
                justifyContent: screens.md ? "space-between" : "center",
                alignItems: "center",
                color: "#fff",
                padding: screens.md ? "0 64px" : "48px 16px",
              }}
            >
              <Typography.Paragraph
                level={5}
                style={{
                  margin: 0,
                  overflow: "visible",
                  whiteSpace: "normal",
                  fontSize: screens.md ? 16 : 14,
                  fontWeight: "600",
                  textAlign: screens.md ? "left" : "center",
                }}
              >
                Hello {userData?.user ? userData?.user.username : "Admin"},
                Welcome back
              </Typography.Paragraph>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: screens.md ? "96px" : "16px",
                  marginTop: screens.md ? 0 : "16px",
                  width: screens.md ? "auto" : "100%",
                  justifyContent: screens.md ? "flex-start" : "space-between",
                }}
              >
                <Badge
                  count={
                    <BellFilled style={{ color: "#3375e3", fontSize: 16 }} />
                  }
                  offset={[0, 0]}
                >
                  <MessageOutlined style={{ color: "#747474", fontSize: 24 }} />
                </Badge>
                <SearchOutlined style={{ fontSize: 24, color: "#747474" }} />
              </div>
            </Header>

            <Content
              style={{
                margin: "0 16px",
              }}
            >
              <Breadcrumb
                style={{
                  margin: "16px 0",
                }}
              >
                <Breadcrumb.Item>
                  <div
                    style={{
                      fontWeight: "600",
                      marginLeft: 12,
                      fontFamily: "sans-serif",
                      fontSize: 14,
                    }}
                  >
                    {checkIsSubMenu()
                      ? activeKey === "12" || activeKey === "13"
                        ? "Sites"
                        : "Settings"
                      : activeName}
                  </div>
                </Breadcrumb.Item>
                {checkIsSubMenu() && (
                  <Breadcrumb.Item>
                    {" "}
                    <div
                      style={{
                        fontWeight: "600",
                        fontFamily: "sans-serif",
                        fontSize: 14,
                      }}
                    >
                      {activeName}
                    </div>
                  </Breadcrumb.Item>
                )}
              </Breadcrumb>

              <div
                style={{
                  padding: 24,
                  minHeight: 360,
                }}
              >
         
                <Routes>
                  <Route path="/" element={<LoginSignup />} />
                  <Route element={<AdminRoute />}>
                    <Route path="/dashboard" element={<DashboardInfo />} />

                    <Route path="/site/admin-sites" element={<AdminSites />} />
                    <Route path="/site/user-sites" element={<Sites />} />

                    <Route path="/subscribed-sites" element={<Subscribedsites />} />
                    <Route path="/add-new-property" element={<AddNewProperty />} />
                    <Route path="/agents" element={<Agents />} />
                    <Route path="/plans" element={<Plans />} />

                    <Route path="/settings/admin-profile" element={<Settings />} />
                    <Route path="/settings/delete-account" element={<DeleteAccount />} />
                    <Route path="/settings/contactus" element={<ContactUs />} />
                    <Route path="/settings/faqs" element={<FAQS />} />
                    <Route path="/settings/inspection" element={<Inspection />} />
                  </Route>
                </Routes>
              </div>
            </Content>
            <Footer style={footerStyle}>
              <Typography.Paragraph
                style={{ fontFamily: "sans-serif", fontSize: 12 }}
              >
                {" "}
                MakeMake My Propertyz ©{new Date().getFullYear()}
              </Typography.Paragraph>
            </Footer>
          </Layout>
        </Layout>
      </Flex>
    </ConfigProvider> ): (
   <Navigate to="/login" replace />
    )}
    />
    </Routes> 
  );
}
