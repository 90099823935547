import React from "react";

const Cardcomponent = () => (
    <div style={{ padding: 5, background: '#f5f5f5', width: 310, borderRadius: 8,margin:10 }}>
    <div>
      <div>
        <img
          alt="example"
          src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
          width={300}
          style={{ borderRadius: 8 }}
        />
      </div>

      <div style={{ color: '#000' }}>
        <div style={{ margin: '5px 0' }}>Abcd Apertment</div>
        <div style={{ fontSize: 12, color: '#607D8B', margin: '5px 0' }}>
          Divine Shanthi nagar, sulur, Coimbatore
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '5px 0',
          }}
        >
          <div style={{ margin: 2 }}>
            <div>2+</div>
            <div>Experience</div>
          </div>
          <div style={{ margin: 2 }}>
            <div>11+</div>
            <div>Apartment</div>
          </div>
          <div style={{ margin: 2 }}>
            <div>10+</div>
            <div>Villas</div>
          </div>
          <div style={{ margin: 2 }}>
            <div>2+</div>
            <div>Upcoming</div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems:'center',
            margin: '5px 0',
          }}
        >
          <div style={{ color: 'green' }}>MMP Verified</div>
          <div style={{ background: '#0961F5',padding:'5px 20px', textAlign:'center', borderRadius:6, color:'#fff' }}>Profile</div>
        </div>
        {/* <div></div>
        <div></div>
        <div></div> */}
      </div>
    </div>
  </div>
);
export default Cardcomponent;
