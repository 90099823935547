import React from "react";
import { Form, Input } from "antd";


const CustomFormDescriptionField = ({
  label,
  required,
  errorMessage,
  placeholder,
  size,
  onChange,
  value,
  maxLength,
  showCount,
  name
}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={[{ required: required, message: errorMessage }]}
    >
          
      <Input.TextArea
        showCount={showCount}
        size={size}
        style={{ width: "100%", height:150 }}   
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={onChange}
        value={value}

      />
      
    </Form.Item>
  );
};

export default CustomFormDescriptionField;
