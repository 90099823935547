import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { isAuthenticated } from '../Auth/auth';

const AdminRoute = () => {
  return isAuthenticated() && isAuthenticated()?.user?.role !== 0 ? (
          <Outlet />
        ) : (
          <Navigate to="/" replace />
        )
};

export default AdminRoute;