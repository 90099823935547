import React, { useEffect, useState } from 'react';
import { ConfigProvider, Layout, Space, Table, Button, Row, Modal, Input, Typography, Form, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined, SaveOutlined, CloseOutlined, DeleteFilled } from "@ant-design/icons";
import {getAllfaqslist, addNewFaq, updateFaq, delteFaq} from "../Auth/settings";

const { Content } = Layout;

const FAQS = () => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [faqData, setFaqData] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(()=>{
    getListOfFaqs();
    },[]);

   const getListOfFaqs = async () => {
      const response = await getAllfaqslist();
      if(response && response.faqs.length > 0) {
        setFaqData(response.faqs);
      }
    }
  


  const columns = [
    { title: 'Id', dataIndex: '_id', key: '_id' },
    { title: 'Question', dataIndex: 'question', key: 'question' },
    { title: 'Answer', dataIndex: 'answer', key: 'answer' },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            icon={<EditOutlined />}
            shape="round"
            type="link"
            size={24}
            style={{ color: "orange", fontWeight: "bold" }}
            onClick={() => editFAQ(record)}
          >
            Edit
          </Button>
          <Popconfirm
                                title="Delete the Admin Site"
                                description="Are you sure to delete this site?"
                                onConfirm={()=>handleDelete(record)}
                                okText="Delete"
                                cancelText="Cancel"
                                icon={<DeleteFilled style={{ color: "red" }} />}
                                placement="top"
                              >
          <Button
            icon={<DeleteOutlined />}
            shape="round"
            type="link"
            size={24}
            style={{ color: "#f00", fontWeight: "bold" }}
          >
            Delete
          </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const showModal = () => {
    setEditingId(null);
    form.resetFields();
    setOpen(true);
  };

  const editFAQ = (record) => {
    setEditingId(record?._id);
    form.setFieldsValue({ questionText: record?.question, answerText: record?.answer });
    setOpen(true);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        
        setConfirmLoading(true);
        if (editingId) {
          // Edit existing FAQ
          const updateDetails = {id:editingId, question: values.questionText, answer: values.answerText, postedBy: JSON.parse(localStorage?.getItem('userDetails'))?.user?._id };
          const response = await updateFaq(updateDetails)
          if(response) {
            getListOfFaqs()
            setOpen(false);
            setConfirmLoading(false);
            form.resetFields();
            setEditingId(null);
          
        }
         
        } else {
          // Add new FAQ
          const newFAQ = {question: values.questionText, answer: values.answerText, postedBy: JSON.parse(localStorage?.getItem('userDetails'))?.user?._id };
          const response = await addNewFaq(newFAQ)
          if(response) {
              getListOfFaqs()
              setOpen(false);
              setConfirmLoading(false);
              form.resetFields();
              setEditingId(null);
            
          }
        }
      })
      .catch((info) => {
        console.log('Validation Failed:', info);
      });
  };

  const handleDelete = async (record) => {
    const response = await delteFaq({id: record?._id})
    if(response) {
      getListOfFaqs();
    }
  }

  const handleCancel = () => {
    setOpen(false);
    setEditingId(null);
    form.resetFields();
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: { headerBg: "#e7e7e7" }
        }
      }}
    >
      <Layout>
        <Content style={{ padding: "20px" }}>
          <Row justify={"end"} style={{ margin: 8 }}>
            <Button type="primary" onClick={showModal}>Add FAQ</Button>
          </Row>
           <Table columns={columns} dataSource={faqData} pagination={false} bordered /> 
          <Modal
            title={editingId ? "Edit FAQ" : "Add a New FAQ"}
            open={open}
            onOk={handleOk}
            okButtonProps={{ icon: <SaveOutlined /> }}
            cancelButtonProps={{ icon: <CloseOutlined /> }}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            okText={"Post"}
            width={800}
          >
            <Form form={form} layout="vertical" name="faqForm">
              <Form.Item
                label={<Typography.Title level={5}>Question</Typography.Title>}
                name="questionText"
                rules={[{ required: true, message: 'Please write your question!' }]}
              >
                <Input.TextArea placeholder="Please Write Your Question" />
              </Form.Item>
              <Form.Item
                label={<Typography.Title level={5}>Answer</Typography.Title>}
                name="answerText"
                rules={[{ required: true, message: 'Please write your answer!' }]}
              >
                <Input.TextArea rows={4} placeholder="Please Write Your Answer" />
              </Form.Item>
            </Form>
          </Modal>
        </Content>
      </Layout>
    </ConfigProvider>
  );
};

export default FAQS;