import React, {useEffect, useState} from 'react';
import { Button, Table, Space, Dropdown } from 'antd';
import {HistoryOutlined,CheckOutlined,PlusOutlined} from "@ant-design/icons"
import { createStyles } from 'antd-style';
import { getInspectionlist } from '../Auth/settings';

const useStyle = createStyles(({ css, token }) => {
  const { antCls } = token;
  return {
    customTable: css`
      ${antCls}-table {
        ${antCls}-table-container {
          ${antCls}-table-body,
          ${antCls}-table-content {
            scrollbar-width: thin;
            scrollbar-color: #eaeaea transparent;
            scrollbar-gutter: stable;
          }
        }
      }
    `,
  };
});



const Inspection = () => {

const [inspectionList, setInspectionList] = useState([]);


  useEffect(() => {
    getInspectionApicall()
  }, [])

  const onMenuClick = (e) => {
    alert(JSON.stringify("Clicked On " +e.key));
  };

  const items = [
    {
      label: 'New',
      key: 'New',
      icon: <PlusOutlined />,
      style:{background:"#4CAF50",color:"#fff",margin:8,fontWeight:"bold"}
    },
    {
      label: 'InProgress',
      key: 'InProgress',
      icon: <HistoryOutlined />,
      style:{background:"#FFC107",color:"#fff",margin:8,fontWeight:"bold"}
    },
    {
      label: 'Completed',
      key: 'Completed',
      icon: <CheckOutlined />,
      style:{background:"#2196F3",color:"#fff", margin:8,fontWeight:"bold"}
      

    }
  ];
  const columns = [
    {
      title: 'Name',
      width: 200,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
    },
    {
      title: 'I Am',
      width: 50,
      dataIndex: 'iAm',
      key: 'iAm',
      fixed: 'left',
    },
    {
      title: 'Property Name',
      dataIndex: 'propertyName',
      key: 'propertyName',
      width: 150,
    },
    {
      title: 'Booking Date',
      dataIndex: 'selectBookingDate',
      key: 'selectBookingDate',
      width: 150,
    },
    {
      title: 'Booking Time',
      dataIndex: 'selectTime',
      key: 'selectTime',
      width: 150,
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
      width: 150,
    },
    {
      title: 'E-Mail',
      dataIndex: 'emailId',
      key: 'emailId',
      width: 150,
    },
    {
      title: 'Address',
      dataIndex: 'completeAddress',
      key: 'completeAddress',
      width: 220,
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      width: 150,
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      width: 150,
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      width: 150,
    },
    {
      title: 'Posted By Name',
      dataIndex: 'postedByname',
      key: 'postedByname',
      width: 200,
    },
    {
      title: 'Posted By Email',
      dataIndex: 'postedByEmail',
      key: 'postedByEmail',
      width: 150,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 250,
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 250,
    },
    {
      title: 'Status',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: () => <Dropdown
      menu={{
        items,
        onClick: onMenuClick,
        style:{}
      }}
    >
      <a>Follow Up</a>
    </Dropdown>
    },
  ];



  const getInspectionApicall = async () => {
    const response = await getInspectionlist();
    if(response && response?.properties?.length > 0) {
      const filterList = response.properties.map((item)=>{
        const {postedBy, ...rest} = item;
        const postedByname = postedBy?.username;
        const postedByEmail = postedBy?.email;
        return {...rest, postedByname:postedByname, postedByEmail:postedByEmail}
    });
    setInspectionList(filterList);
    }
  }

  const { styles } = useStyle();
  return (
    <Table
      className={styles.customTable}
      columns={columns}
      dataSource={inspectionList}
      scroll={{
        x: 'max-content',
        y:600,
      }}
    />
  );
};
export default Inspection;