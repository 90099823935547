// import React, { useState } from 'react';
// import AWS from 'aws-sdk';
// import UploadImg from '../assets/uploaddetails.png'

// function ImageUpload() {
//   const [selectedImage, setSelectedImage] = useState(null);
//   const [uploadedImage, setUploadedImage] = useState(null);

//   const handleImageChange = (event) => {
//     setSelectedImage(event.target.files[0]);
//   };

// const S3_BUCKET ='mmp-upload';
// const REGION ='ap-south-1';

//   const handleImageUpload = async () => {
//     try {
//       const s3 = new AWS.S3({
//         accessKeyId: 'AKIAXTORPZNUUHTHTDUK',
//         secretAccessKey: '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',
//         region: REGION
//       });

//       const params = {
//         Bucket: S3_BUCKET,
//         Key: `images/${selectedImage.name}`,
//         Body: selectedImage
//       };

//       const data = await s3.upload(params).promise();
//       setUploadedImage(data.Location);
//       console.log('Image uploaded successfully:', data);
//     } catch (error) {
//       console.error('Error uploading image:', error);
//     }
//   };

//   return (
//     <div>
//       {/* <div className="image-upload-container">
//     <button className="image-upload-button">Upload Image</button>
//     <input type="file" id="file-input" accept="image/*" style={{"display": "none"}} />
//     <div className="image-preview" style={{display: "none"}}>
//         <img id="image-preview-img" src={UploadImg} alt="Uploaded Image" />
//     </div>
// </div> */}
// <div className='imgupload_container'>
//       <img src={UploadImg} alt='img' style={{height:'100px',width:'100px'}} />
//       <input type="file" id="file-input" accept="image/*" className="img_input" />
//       <button id="file-button">Select File</button>

//       {/* <input type="file" onChange={handleImageChange} className='img_input' />
//       <button onClick={handleImageUpload}>Upload Image</button> */}
// </div>

//       {uploadedImage && <img src={uploadedImage} style={{height:100,width:100}} alt="Uploaded Image" />}
//     </div>
//   );
// }

// export default ImageUpload;

// // import React, { useState } from 'react';
// // import AWS from 'aws-sdk';

// // function ImageUpload() {
// //   const [selectedImages, setSelectedImages] = useState([]);
// //   const [uploadedImages, setUploadedImages] = useState([]);

// //   const handleImageChange = (event) => {
// //     setSelectedImages([...selectedImages, ...event.target.files]);
// //   };

// // const S3_BUCKET ='mmp-upload';
// // const REGION ='ap-south-1';

// //   const handleImageUpload = async () => {
// //     try {
// //       const s3 = new AWS.S3({
// //         accessKeyId: 'AKIAXTORPZNUUHTHTDUK',
// //         secretAccessKey: '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',
// //         region: REGION
// //       });

// //       const promises = selectedImages.map(async (image) => {
// //         const params = {
// //           Bucket:S3_BUCKET,
// //           Key: `images/${image.name}`,
// //           Body: image
// //         };

// //         const data = await s3.upload(params).promise();
// //         return data.Location;
// //       });

// //       const uploadedImageUrls = await Promise.all(promises);
// //       setUploadedImages(uploadedImageUrls);
// //       console.log('Images uploaded successfully:', uploadedImageUrls);
// //     } catch (error) {
// //       console.error('Error uploading images:', error);
// //     }
// //   };

// //   return (
// //     <div>
// //       <input type="file" multiple onChange={handleImageChange} />
// //       <button onClick={handleImageUpload}>Upload Images</button>
// //       <ul>
// //         {uploadedImages.map((url) => (
// //           <li key={url}>
// //             <img src={url} alt="Uploaded Image" />
// //           </li>
// //         ))}
// //       </ul>
// //     </div>
// //   );
// // }

// // export default ImageUpload;

import React, { useState } from "react";
import AWS from 'aws-sdk';
import UploadImg from "../assets/uploaddetails.png";

const UploadImgComp = ({imgData,listofImages, setListofImages}) => {

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const updatedImage = { ...imgData, imgurl: reader.result,filename: file};
  
        setListofImages((prevList) => {
          // Use a closure to capture the index
          return prevList.map((image, index) => {
            if (image.name === imgData.name) {
              return updatedImage;
            }
            return image;
          });
        });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="image-container" key={imgData.id}>
      <img
        src={imgData.imgurl}
        alt="Image"
        onClick={() => document.getElementById(imgData.name).click()}
      />
      <input
        type="file"
        id={imgData.name}
        accept="image/*"
        onChange={handleImageChange}
        style={{ display: "none" }}
      />
    </div>
  );
};

export default UploadImgComp;
